@import '../../../../../assets/styles/variables.scss';
@import '../../../../../assets/styles/mixin';

.profileSettings {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 499px) {
        flex-direction: column;
    }

    &__form {
        display: flex;
        flex-direction: column;
        margin-top: $desctop20px;
        width: 45%;

        @media only screen and (max-width: 499px) {
            margin-top: $mobile20px;
            width: 100%;
        }

        &_inputContainer {
            margin-bottom: $desctop40px;
            position: relative;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile34px;
            }
        }

        &_input {
            border: $desctop1px solid rgba(38, 38, 66, 0.1);
            color: $tolopeaColor;
            font-size: $desctop13px;

            @media only screen and (max-width: 499px) {
                border: $mobile1px solid rgba(38, 38, 66, 0.1);
                font-size: $mobile13px;
            }
        }

        &_labelActive{
            color: $tolopeaColor;
        }

        &_label {
            color: $ghostColor;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        &__error {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            bottom: -1.5vw;

            @media only screen and (max-width: 499px) {
                bottom: -5.3vw;
                align-items: flex-end;
            }

            &_icon {
                width: $desctop16px;
                height: $desctop16px;
                margin-right: $desctop10px;

                @media only screen and (max-width: 499px) {
                    width: $mobile16px;
                    height: $mobile16px;
                    margin-right: $mobile7px;
                }

                path {
                    fill:$redColor;
                }
            }

            &_text {
                font-family: $Montserrat500;
                font-size: $desctop12px;
                color: $tolopeaColor;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile12px;
                }
            }
        }
    }
}

.rightSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 499px) {
        width: 100%;
        display: block;
    }

    & > * {
        outline: none;
    }

    &__top {
        display: flex;
        align-items: center;
        margin: 0 0 $desctop25px 0;
        cursor: pointer;
        transition: all 0.25s;


        @media only screen and (max-width: 499px) {
            margin: 0 0 $mobile25px 0;
        }

        &:hover {
            .rightSide__top_icon {
                background: $redColor;
            }

            .rightSide__top_svg {
                path {
                    fill: #fff;
                }
            }
        }

        &_delete {
            font-family: $Montserrat500;
            font-size: $desctop14px;
            line-height: 150%;
            color: $redColor;
            margin-top: $desctop10px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile14px;
                margin-top: $mobile10px;
            }
        }

        &_textWrapper {
            display: flex;
            flex-direction: column;
        }

        &_icon,
        &_iconActive,
        &_avatar,
        &_spinner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: $desctop140px;
            height: $desctop140px;
            margin-right: $desctop20px;
            background: $whiteSmokeColor;
            border-radius: $desctop30px;

            @media only screen and (max-width: 499px) {
                width: 38.89vw;
                height: 38.89vw;
                margin-right: $mobile15px;
                border-radius: $mobile30px;
            }
        }

        &_spinner {
            img {
                width: 100%;
            }
        }

        &_avatar {
           user-select: none;
           pointer-events: none;
           object-fit: cover;
        }

        &_iconActive {
            background: $redColor;
        }

        &_svg,
        &_svgActive {
            width: $desctop100px;
            height: $desctop100px;

            @media only screen and (max-width: 499px) {
                width: 27.78vw;
                height: 27.78vw;
            }
        }

        &_svgActive {
            path {
                fill: $whiteColor;
            }
        }

        &_textDesctop,
        &_textMobile {
            font-family: $Montserrat800;
            font-size: $desctop20px;
            line-height: 110%;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile19px;
            }
        }

        &_textDesctop {

            @media only screen and (max-width: 499px) {
                display: none;
            }
        }

        &_textMobile {
            display: none;

            @media only screen and (max-width: 499px) {
                display: block;
            }
        }
    }

    &__bottom {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: 150%;
        color: $tolopeaColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
        }
    }
}

.buttonWrapper {

    &__button {
        @include filledButton;
        margin-right: $desctop30px;
        letter-spacing: 0.05em;
        align-items: center;
        padding: $desctop17px $desctop25px;
        min-width: 10.45vw;
        height: $desctop45px;
        text-transform: uppercase;

        @media only screen and (max-width: 499px) {
            margin: $mobile42px 0 $mobile15px 0;
            width: 100%;
            min-width: $mobile150px;
            height: $mobile44px;
        }

        &_icon {
            width: $desctop26px;
            height: $desctop26px;
            margin-right: $desctop15px;

            @media only screen and (max-width: 499px) {
                width: $mobile26px;
                height: $mobile26px;
                margin-right: $mobile15px;
            }
        }
    }
}
