@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.wrapper {

    &__network {
        position: relative;
        margin-right: $desctop20px;
        cursor: pointer;
        padding: $desctop5px;

        @media only screen and (max-width: 499px) {
            position: absolute;
            right: $mobile20px;
            bottom: 9vw;
        }
    }

    &__icon,
    &__iconActive {
        width: $desctop24px;
        height: $desctop24px;

        @media only screen and (max-width: 499px) {
            width: $mobile28px;
            height: $mobile28px;

            path {
                fill: $redColor;
            }
        }

        &:hover {
            path {
                fill: $redColor;
            }
        }
    }

    &__dropDown {
        position: absolute;
        background: $whiteColor;
        border: $desctop1px solid rgba(36, 36, 68, 0.1);
        border-radius: $desctop17px;
        padding: $desctop10px;
        height: fit-content;
        top: 3vw;
        left: -10vw;
        width: 12vw;
        z-index: 999999;

        @media only screen and (max-width: 499px) {
            border: $mobile1px solid rgba(36, 36, 68, 0.1);
            border-radius: $mobile17px;
            padding: $mobile10px;
            top: -25vw;
            left: -31vw;
            width: 40vw;
            height: 21vw;
        }

        &_item,
        &_itemActive {
            font-family: $Montserrat500;
            font-size: $desctop10px;
            line-height: $desctop10px;
            letter-spacing: $desctop1px;
            text-transform: uppercase;
            text-align: center;
            color: $tolopeaColor;
            padding: $desctop10px;
            cursor: pointer;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
                letter-spacing: $mobile1px;
                padding: $mobile10px;
            }

            &:first-child {
                border-bottom: $desctop2px solid rgba(36, 36, 68, 0.1);

                @media only screen and (max-width: 499px) {
                    border-bottom: $mobile1px solid rgba(36, 36, 68, 0.1);
                }
            }

            &:hover {
                color: $redColor;
            }
        }

        &_itemActive {
            color: $redColor;
        }
    }
}
