@import '../../../../../assets/styles/variables.scss';
@import '../../../../../assets/styles/mixin';


.wrapper {
    display: flex;
}

.form {
    width: 50%;
    padding: $desctop20px $desctop60px 0 0;

    @media only screen and (max-width: 499px) {
        margin-top: $mobile20px;
        width: 100%;
        padding: 0;
    }

    &__inputContainer {
        margin-bottom: $desctop40px;
        position: relative;

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile34px;
        }
    }

    &__error {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: -1.5vw;

        @media only screen and (max-width: 499px) {
            bottom: -5.3vw;
            align-items: flex-end;
        }

        &_icon {
            width: $desctop16px;
            height: $desctop16px;
            margin-right: $desctop10px;

            @media only screen and (max-width: 499px) {
                width: $mobile16px;
                height: $mobile16px;
                margin-right: $mobile7px;
            }

            path {
                fill:$redColor;
            }
        }

        &_text {
            font-family: $Montserrat500;
            font-size: $desctop12px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
            }
        }
    }

    &__input {
        border: $desctop1px solid rgba(38, 38, 66, 0.1);
        color: $tolopeaColor;
        font-size: $desctop13px;

        @media only screen and (max-width: 499px) {
            border: $mobile1px solid rgba(38, 38, 66, 0.1);
            font-size: $mobile13px;
        }
    }

    &__labelActive{
        color: $tolopeaColor;
        font-size: $desctop12px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
        }
    }

    &__label {
        color: $ghostColor;
        font-family: $Montserrat500;
        font-size: $desctop12px;
        line-height: $desctop19px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }
    }
}

.buttonWrapper {
    margin: $desctop50px 0 0 0;

    @media only screen and (max-width: 499px) {
        margin: $mobile30px 0 0;
    }

    &__button {
        @include filledButton;
        margin-right: $desctop30px;
        letter-spacing: 0.05em;
        align-items: center;
        padding: $desctop17px $desctop25px;
        text-transform: uppercase;
        min-width: $desctop160px;
        height: $desctop45px;

        @media only screen and (max-width: 499px) {
            margin: $mobile42px 0 $mobile15px 0;
            width: 100%;
            min-width: $mobile150px;
            height: $mobile44px;
        }

        &_icon {
            width: $desctop26px;
            height: $desctop26px;
            margin-right: $desctop15px;

            @media only screen and (max-width: 499px) {
                width: $mobile26px;
                height: $mobile26px;
                margin-right: $mobile15px;
            }
        }
    }
}

.passwordErrors {
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    transition: max-height 0.4s, opacity 0.4s, visibility 0.4s, padding 0.1s ease;

    &__hasError {
        max-height: 100%;
        opacity: 1;
        visibility: visible;
        padding: 0.2vw 0 0 0;

        @media only screen and (max-width: 499px) {
            padding: $mobile7px 0 0 0;
            max-height: 40vw;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        font-family: $Montserrat500;
        font-size: $desctop12px;
        line-height: 150%;
        color: $tolopeaColor;
        margin: 0 $desctop15px $desctop10px 0;
        width: 100%;

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            margin: 0 $mobile15px 0 0;
            width: auto;
        }
    }

    &__dot {
        display: block;
        border-radius: 50%;
        width: $desctop6px;
        height: $desctop6px;
        background: $redColor;
        margin-right: $desctop10px;

        @media only screen and (max-width: 499px) {
            width: $mobile6px;
            height: $mobile6px;
            margin-right: $mobile10px;
        }
    }

    &__mobile {
        display: none;

        @media only screen and (max-width: 499px) {
          display: flex;
        }
    }

    &__desctop {
        width: 50%;

        @media only screen and (max-width: 499px) {
           display: none;
        }

        &_title {
            font-family: $Montserrat800;
            font-size: $desctop16px;
            line-height: 1vw;
            color: $tolopeaColor;
            margin-bottom: $desctop15px;
            width: 100%;
        }
    }
}
