@import '../.././assets/styles/variables';

.pageNotFound {
    display: flex;
    align-items: center;
    justify-items: center;
    flex: 1;
    min-height: 70vh;
    padding: 5vw 0;

    img {
        margin: 0 auto;
        width: 16vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 30%;
        }

        @media only screen and (max-width: 499px) {
            width: 50vw;
        }
    }
}
