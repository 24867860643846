@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixin';

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $desctop20px;
    max-width: 59.5vw;
    border-radius: $desctop8px;
    background: $whiteColor;
    box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);

    @media only screen and (max-width: 499px) {
        padding: 0;
        max-width: 100%;
        border-radius: initial;
        box-shadow: initial;
    }
}

.header {
    display: flex;
    align-items: center;
    border-bottom: $desctop1px solid $solitudeColor;
    padding: $desctop11px;
    margin-bottom: $desctop18px;

    @media only screen and (max-width: 499px) {
        justify-content: space-between;
        border-bottom: $mobile1px solid $solitudeColor;
        padding: 0 0 $mobile15px 0;
        margin-bottom: $mobile20px;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $tolopeaColor;
        margin-right: $desctop15px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile15px;
            line-height: $mobile26px;
            margin-right: $mobile15px;
        }
    }

    &__add {
        display: flex;
        align-items: center;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $redColor;
        cursor: pointer;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }

        &:hover {
            opacity: 0.5;
        }

        img {
            width: $desctop13px;
            height: $desctop13px;
            margin-right: $desctop8px;

            @media only screen and (max-width: 499px) {
                width: $mobile16px;
                height: $mobile16px;
                margin-right: $mobile7px;
            }
        }
    }
}

.totalContractsFound {
    font-family: $Montserrat500;
    font-size: $desctop12px;
    line-height: $desctop19px;
    color: $ghostColor;

    @media only screen and (max-width: 499px) {
        border-bottom: $mobile1px solid $solitudeColor;
        padding-bottom: $mobile20px;
        font-size: $mobile12px;
        line-height: $mobile19px;
    }
}

.table {
    margin: $desctop35px 0;

    @media only screen and (max-width: 499px) {
        margin: 0;
    }

    &__rowWrapper {
        padding: $desctop11px;
        border-bottom: $desctop1px solid $solitudeColor;
    }

    &__head,
    &__row {
        display: flex;

        div {
            font-family: $Montserrat900;
            font-size: $desctop12px;
            line-height: $desctop19px;
            color: $ghostColor;

            &:nth-child(1) {
                width: 17%;

                @media only screen and (max-width: 499px) {
                    order: 6;
                    width: 45%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                }
            }

            &:nth-child(2) {
                width: 14%;

                @media only screen and (max-width: 499px) {
                    order: 3;
                    width: 100%;
                    margin-bottom: 4vw;
                }
            }

            &:nth-child(3) {
                width: 14%;

                @media only screen and (max-width: 499px) {
                    order: 4;
                    width: 100%;
                    margin-bottom: 4vw;
                }
            }

            &:nth-child(4) {
                width: 23.5%;

                @media only screen and (max-width: 499px) {
                    order: 1;
                    width: 100%;
                    margin-bottom: 4vw;
                }
            }

            &:nth-child(5) {
                width: 25.5%;

                @media only screen and (max-width: 499px) {
                    order: 2;
                    width: 100%;
                    margin-bottom: 4vw;
                }
            }

            &:nth-child(6) {
                width: 7%;

                @media only screen and (max-width: 499px) {
                    order: 5;
                    width: 45%;
                }
            }
        }
    }

    &__head {
        padding: $desctop11px;
        border-bottom: $desctop1px solid $solitudeColor;

        @media only screen and (max-width: 499px) {
            display: none;
        }
    }

    &__row {

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            padding: $mobile30px 0;
        }

        &_button {
            @include filledButton;
            min-width: $desctop75px;
            height: $desctop30px;
            padding: 0 $desctop10px;

            @media only screen and (max-width: 499px) {
                min-width: $mobile75px;
                height: $mobile30px;
                padding: 0 $mobile10px;
            }
        }

        &_address {

            a,
            p {
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 90%;
                white-space: nowrap;

                &:last-child {
                    font-family: $Montserrat500;
                    font-size: $desctop10px;
                    line-height: $desctop19px;
                    color: $ghostColor;

                    @media only screen and (max-width: 499px) {
                        display: none;
                    }
                }
            }


            a {
                font-family: $Montserrat500;
                font-size: $desctop13px;
                line-height: $desctop19px;
                color: $redColor;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile13px;
                    line-height: $mobile19px;
                }

                &:hover {
                    text-decoration: underline;
                    color: $redColor;
                }
            }
        }

        &_content {
            p {
                display: block;
                font-family: $Montserrat500;
                font-size: $desctop13px;
                line-height: $desctop19px;
                color: $tolopeaColor;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 90%;
                white-space: nowrap;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile13px;
                    line-height: $mobile19px;
                }
            }
        }

        &_mobileTitle {
            display: none;

            @media only screen and (max-width: 499px) {
                display: flex;
                font-family: $Montserrat800;
                font-size: $mobile13px;
                line-height: $mobile19px;
                margin-bottom: $mobile7px;
            }
        }

        &_more {
            font-family: $Montserrat600 !important;
            color: $redColor !important;
            cursor: pointer;

            @media only screen and (max-width: 499px) {
                display: flex;
                align-items: center;
                text-decoration: underline;
                font-size: $mobile13px !important;
                line-height: $mobile19px !important;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.pagination {
    margin-top: auto;

    ul {
        width: 80%;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin: 0 0 20vw 0;
        }
    }
}

.noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;

    @media only screen and (max-width: 499px) {
        margin: $mobile25px auto $mobile25px;
    }

    &__icon {
        width: $desctop200px;
        height: $desctop200px;

        @media only screen and (max-width: 499px) {
            width: 50vw;
            height: 50vw;
        }
    }

    &__title {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $blackColor;
        margin: $desctop20px 0;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
            margin: $mobile20px 0;
        }
    }
}

.edit {
    margin-top: $desctop12px;
    width: 90%;

    @media only screen and (max-width: 499px) {
        margin-top: $mobile12px;
        width: 100%;
    }

    &__row {
        display: flex;
        align-items: center;
        margin-bottom: $desctop30px;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: $mobile30px;
        }

        &_label {
            font-family: $Montserrat400;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $ghostColor;
            min-width: 11vw;
            margin-right: $desctop10px;

            @media only screen and (max-width: 499px) {
                font-family: $Montserrat900;
                font-size: $mobile13px;
                line-height: $mobile19px;
                min-width: 19vw;
                margin: 0 0 $mobile7px 0;
            }
        }

        &_value {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                max-width: 100%;
                font-size: $mobile13px;
                line-height: $mobile19px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    &__text {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $tolopeaColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &__inputContainer {
        position: relative;
        width: 100%;
    }

    &__input {
        border: $desctop1px solid rgba(38, 38, 66, 0.1);
        color: $tolopeaColor;
        font-size: $desctop13px;

        @media only screen and (max-width: 499px) {
            border: $mobile1px solid rgba(38, 38, 66, 0.1);
            font-size: $mobile13px;
        }

        &::placeholder {
            color: $ghostColor;
        }
    }

    &__error {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: -1.5vw;

        @media only screen and (max-width: 499px) {
            bottom: -5.3vw;
            align-items: flex-end;
        }

        &_icon {
            width: $desctop16px;
            height: $desctop16px;
            margin-right: $desctop10px;

            @media only screen and (max-width: 499px) {
                width: $mobile16px;
                height: $mobile16px;
                margin-right: $mobile7px;
            }

            path {
                fill:$redColor;
            }
        }

        &_text {
            font-family: $Montserrat500;
            font-size: $desctop12px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
            }
        }
    }

    &__buttonWrapper {
        display: flex;
        justify-content: center;
        margin-top: $desctop70px;

        @media only screen and (max-width: 499px) {
            margin-top: $mobile42px;
            flex-wrap: wrap;
        }

        &_leftSide {
            display: flex;

            @media only screen and (max-width: 499px) {
                width: 100%;
                justify-content: space-between;
            }
        }

        &_back,
        &_save,
        &_remove {
            @include mainBtnStyle;
            min-width: $desctop140px;
            height: $desctop40px;
            text-transform: uppercase;
            font-family: $Montserrat900;

            @media only screen and (max-width: 499px) {
                min-width: $mobile150px;
                height: $mobile44px;
            }
        }

        &_back {
            @include grayTransparentButton;
            margin-right: $desctop10px;
        }

        &_save {
            @include transparentButton;
            border-color: $redColor !important;
            color: $redColor;
        }

        &_remove {
            @include filledButton;
            margin-left: auto;

            @media only screen and (max-width: 499px) {
                margin-right: auto;
                margin-left: 0;
                margin-top: $mobile25px;
            }

            img {
                width: $desctop9px;
                height: $desctop11px;
                margin-right: $desctop12px;

                @media only screen and (max-width: 499px) {
                    width: $mobile10px;
                    height: $mobile10px;
                    margin-right: $mobile12px;
                }
            }
        }
    }
}

.more {
    display: flex;
    justify-content: space-between;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    transition: max-height 0.4s, opacity 0.4s, visibility 0.4s, padding 0.1s ease;

    @media only screen and (max-width: 499px) {
        transition: initial;
        flex-wrap: wrap;
        box-shadow: 0 $mobile5px $mobile6px rgba(0, 0, 0, 0.05);
        border-radius: $mobile7px;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 23%;
        background: $whiteColor;
        box-shadow: 0 $desctop4px $desctop6px rgba(0, 0, 0, 0.05);
        border-radius: $desctop8px;
        padding: $desctop20px $desctop10px $desctop10px;
        margin: $desctop20px 0;

        @media only screen and (max-width: 499px) {
            width: 44%;
            box-shadow: none;
            border-radius: initial;
            padding: 0;
            margin: 0;
            align-items: flex-start;
            margin-bottom: $mobile20px;
        }
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop12px;
        line-height: $desctop25px;
        color: $ghostColor;
        text-align: center;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
            text-align: left;
            margin-bottom: $mobile7px;
        }
    }

    &__value {
        font-family: $Montserrat500;
        font-size: $desctop12px;
        line-height: $desctop34px;
        color: $tolopeaColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }
    }
}

.moreActive {
    max-height: $desctop120px;
    opacity: 1;
    visibility: visible;
    padding: 0.2vw 0 0 0;

    @media only screen and (max-width: 499px) {
        max-height: fit-content;
        padding: $mobile20px $mobile10px 0;
        margin-bottom: $mobile20px;
    }
}
