@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.pendingTxn {
    background: $whiteColor;
    padding: $desctop45px 6vw $desctop30px 8vw;
    flex: 1;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $midnightExpressColor;
        margin-bottom: $desctop35px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile24px;
            margin: 0 0 $mobile30px 0;
            width: 100%;
        }
    }
}

.table {
    margin-bottom: $desctop30px;

    @media only screen and (max-width: 499px) {
        margin-bottom: $mobile30px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        padding: $desctop10px $desctop20px;
        margin: 0 $desctop5px $desctop15px;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            box-shadow: 0 $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
            border-radius: $mobile7px;
            padding: $mobile10px $mobile20px;
            margin: 0 $mobile5px $mobile15px;
        }

        &_staking {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        &_dotWrapper,
        &_dotWrapperDisabled {
            padding: $desctop10px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
                .transactions__row_dot {
                    opacity: 0.8;
                }
            }
        }

        &_dotWrapperDisabled {
            cursor: default;
        }

        &_dot {
            display: flex;
            align-items: center;
            justify-content: center;
            width: $desctop15px;
            height: $desctop15px;

            @media only screen and (max-width: 499px) {
                width: $mobile15px;
                height: $mobile15px;
            }

            img,svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__label {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $slateGreyColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }
    }

    &__link {
        @include text500red;
    }

    &__text {
        @include text500tolopeaColor;
    }

    &__column1,
    &__column2,
    &__column3,
    &__column4,
    &__column5,
    &__column6,
    &__column7 {
        &>* {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: initial;
        }
    }

    &__column1 {
        width: 39vw;

        @media only screen and (max-width: 499px) {
            width: 87%;
            margin-bottom: $mobile15px;
            order: 1;
        }
    }

    &__column2 {
        width: 13vw;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-bottom: $mobile15px;
            order: 5;
            margin-left: 10.3vw;
        }
    }

    &__column3 {
        width: 12vw;

        @media only screen and (max-width: 499px) {
            width: 42%;
            order: 2;
            margin-left: 10.3vw;
        }
    }

    &__column4 {
        width: 10vw;

        @media only screen and (max-width: 499px) {
            width: 40%;
            margin-bottom: $mobile15px;

            order: 3;
        }
    }

    &__column5 {
        width: 10vw;

        @media only screen and (max-width: 499px) {
            width: 30%;
            margin-bottom: $mobile15px;
            order: 4;
        }
    }

    &__column6 {
        width: 8vw;

        @media only screen and (max-width: 499px) {
            width: 30%;
            order: 6;
        }
    }

    &__column7 {
        width: 8vw;

        @media only screen and (max-width: 499px) {
            width: 30%;
            order: 7;
        }
    }
}

.notFoundIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: $desctop30px 0;
    min-height: 70vh;

    @media only screen and (max-width: 499px) {
        margin: $mobile30px 0;
    }

    img {
        width: 17.5vw;
        height: 16vw;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            width: 60vw;
            height: 52vw;
            margin-bottom: $mobile20px;
        }
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $blackColor;
        margin-bottom: $desctop17px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile24px;
            margin-bottom: 0;
            text-align: center;
        }
    }
}

.details {
    display: flex;
    flex-direction: column;
    background: $whiteColor;
    box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
    border-radius: $desctop8px;
    padding: $desctop14px $desctop40px $desctop14px $desctop40px;
    margin: $desctop3px $desctop3px $desctop22px $desctop3px;
    display: none;

    @media only screen and (max-width: 499px) {
        flex-direction: column;
        padding: $mobile20px;
        box-shadow: 0px $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
        border-radius: $mobile10px;
        margin: $mobile4px;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__arrow {
        min-width: $desctop12px;
        min-height: $desctop12px;
        margin: 2.8vw 2vw 0;

        @media only screen and (max-width: 499px) {
            min-width: $mobile12px;
            min-height: $mobile12px;
            margin: 0 auto $mobile25px;
            transform: rotate(90deg);
        }

        path {
            fill: $tolopeaColor
        }
    }

    &__rightSide,
    &__leftSide {
        width: 100%;
    }

    &__labelWrapper {
        display: flex;
        align-items: center;

        .details__label {
            &:last-child {
                margin-left: auto;
                width: 13.5vw;

                @media only screen and (max-width: 499px) {
                    width: 37.5vw;
                }
            }
        }
    }

    &__label {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $midnightExpressColor;
        margin-bottom: $desctop11px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile14px;
            line-height: $mobile20px;
            margin-bottom: $mobile10px;
        }
    }

    &__rowWrapper {
        display: flex;
        flex-direction: column;
    }

    &__row {
        display: flex;
        align-items: center;
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        padding: $desctop10px $desctop20px;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            box-shadow: none;
            border-radius: $desctop8px;
            padding: 0;
            margin-bottom: $mobile20px;
        }

        &_staking {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        a {
            color: $redColor;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }

            &:hover {
                color: $redColor;
                text-decoration: underline;
            }
        }

        p {
            color: $tolopeaColor;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        &_value {
            display: flex;
            align-items: center;
            margin-left: auto;
            width: 12vw;

            @media only screen and (max-width: 499px) {
                width: 37vw;
            }

            img {
                width: $desctop10px;
                height: $desctop10px;
                margin-right: $desctop8px;

                @media only screen and (max-width: 499px) {
                    min-width: $mobile10px;
                    min-height: $mobile10px;
                    margin-right: $mobile6px;
                }
            }

            p {
                font-family: $Montserrat500;
                font-size: $desctop12px;
                line-height: $desctop17px;
                color: $slateGreyColor;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile12px;
                    line-height: $mobile17px;
                    white-space: nowrap;
                }
            }
        }
    }

    &__total {
        display: flex;
        justify-content: space-between;
        margin-top: $desctop20px;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }

        p {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-family: $Montserrat500;
            font-size: $desctop10px;
            line-height: $desctop17px;
            letter-spacing: $desctop1px;
            color: $slateGreyColor;
            background: rgba(119, 131, 143, 0.1);
            border-radius: $desctop4px;
            padding: $desctop5px $desctop8px;
            width: fit-content;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile17px;
                letter-spacing: $mobile1px;
                border-radius: $mobile5px;
                padding: $mobile5px $mobile7px;
                margin-left: 0;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
                justify-content: center;

                &:not(:last-child) {
                    margin-bottom: $mobile20px;
                }
            }
        }

        img {
            width: $desctop9px;
            height: $desctop9px;
            margin-right: $desctop5px;

            @media only screen and (max-width: 499px) {
                width: $mobile10px;
                height: $mobile10px;
                margin-right: $mobile5px;
            }
        }
    }
}

.detailsOpened {
    display: flex;
}
