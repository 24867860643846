@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixin';

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $desctop20px;
    max-width: 59.5vw;
    border-radius: $desctop8px;
    background: $whiteColor;
    box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
    min-height: 37vw;

    @media only screen and (max-width: 499px) {
        padding: 0;
        max-width: 100%;
        border-radius: initial;
        box-shadow: initial;
    }
}

.header {
    display: flex;
    align-items: center;
    border-bottom: $desctop1px solid $solitudeColor;
    padding: $desctop11px $desctop11px $desctop11px 0;
    margin-bottom: $desctop18px;

    @media only screen and (max-width: 499px) {
        justify-content: space-between;
        border-bottom: $mobile1px solid $solitudeColor;
        padding: 0 0 $mobile15px 0;
        margin-bottom: $mobile20px;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $tolopeaColor;
        margin-right: $desctop15px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile15px;
            line-height: $mobile26px;
            margin-right: $mobile15px;
        }
    }

    &__add {
        display: flex;
        align-items: center;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $redColor;
        cursor: pointer;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }

        &:hover {
            color: rgba(255, 48, 21, 0.5);
        }

        img {
            width: $desctop13px;
            height: $desctop13px;
            margin-right: $desctop8px;

            @media only screen and (max-width: 499px) {
                width: $mobile16px;
                height: $mobile16px;
                margin-right: $mobile7px;
            }
        }
    }
}

.totalContractsFound {
    font-family: $Montserrat500;
    font-size: $desctop12px;
    line-height: $desctop19px;
    color: $ghostColor;

    @media only screen and (max-width: 499px) {
        border-bottom: $mobile1px solid $solitudeColor;
        padding-bottom: $mobile20px;
        font-size: $mobile12px;
        line-height: $mobile19px;
    }
}

.table {
    margin: $desctop35px 0 $mobile10px 0;

    @media only screen and (max-width: 499px) {
        margin: 0 0 $mobile30px 0;
    }

    &__head,
    &__row {
        display: flex;
        padding: $desctop11px;
        border-bottom: $desctop1px solid $solitudeColor;

        div {
            font-family: $Montserrat900;
            font-size: $desctop12px;
            line-height: $desctop19px;
            color: $ghostColor;

            &:nth-child(1) {
                width: 20%;

                @media only screen and (max-width: 499px) {
                    order: 4;
                    width: 30%;
                    display: flex;
                    align-items: flex-end;
                }
            }

            &:nth-child(2) {
                width: 60%;
                display: flex;
                flex-direction: column;

                @media only screen and (max-width: 499px) {
                    order: 1;
                    width: 100%;
                    margin-bottom: $mobile20px;
                }
            }

            &:nth-child(3) {
                width: 20%;

                @media only screen and (max-width: 499px) {
                    order: 3;
                    width: 70%;
                }
            }
        }
    }

    &__head {
        @media only screen and (max-width: 499px) {
            display: none;
        }
    }

    &__row {

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            padding: $mobile30px 0;
        }

        &_button {
            @include filledButton;
            min-width: $desctop75px;
            height: $desctop30px;
            padding: 0 $desctop10px;

            @media only screen and (max-width: 499px) {
                min-width: $mobile75px;
                height: $mobile30px;
                padding: 0 $mobile10px;
                width: 100%;
            }
        }

        &_tokenContractAddress,
        &_created {
            p {
                display: block;
                font-family: $Montserrat500;
                font-size: $desctop13px;
                line-height: $desctop19px;
                color: $tolopeaColor;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 90%;
                white-space: nowrap;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile13px;
                    line-height: $mobile19px;
                }
            }

            a {
                font-family: $Montserrat500;
                font-size: $desctop13px;
                line-height: $desctop19px;
                color: $redColor;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile13px;
                    line-height: $mobile19px;
                }

                &:hover {
                    text-decoration: underline;
                    color: $redColor;
                }
            }
        }

        &_created {
            display: flex;
            align-items: flex-start;

            @media only screen and (max-width: 499px) {
                flex-direction: column;
            }
        }

        &_mobileTitle {
            display: none;

            @media only screen and (max-width: 499px) {
                display: flex;
                font-family: $Montserrat800;
                font-size: $mobile13px;
                line-height: $mobile19px;
                margin-bottom: $mobile7px;
            }
        }

        &_appName {
            font-family: $Montserrat500;
            font-size: $desctop10px;
            line-height: $desctop19px;
            color: $ghostColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile19px;
            }
        }
    }
}

.pagination {
    margin-top: auto;

    ul {
        width: 80%;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin: 0 0 20vw 0;
        }
    }
}

.noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;

    @media only screen and (max-width: 499px) {
        margin: $mobile25px auto $mobile25px;
    }

    &__icon {
        width: $desctop200px;
        height: $desctop200px;

        @media only screen and (max-width: 499px) {
            width: 50vw;
            height: 50vw;
        }
    }

    &__title {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $blackColor;
        margin: $desctop20px 0;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
            margin: $mobile20px 0;
        }
    }
}

.edit {
    margin-top: $desctop12px;
    width: 90%;

    @media only screen and (max-width: 499px) {
        margin-top: $mobile12px;
        width: 100%;
    }

    &__row {
        display: flex;
        align-items: center;
        margin-bottom: $desctop30px;

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile30px;
            flex-direction: column;
            align-items: flex-start;
        }

        &_label,
        &_labelSecond {
            font-family: $Montserrat400;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $ghostColor;
            min-width: 11vw;
            margin-right: $desctop10px;

            @media only screen and (max-width: 499px) {
                font-family: $Montserrat600;
                font-size: $mobile13px;
                line-height: $mobile19px;
                min-width: 19vw;
                margin: 0 0 $mobile7px 0;
            }
        }

        &_value {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    &__inputContainer {
        position: relative;
        width: 100%;
    }

    &__input {
        border: $desctop1px solid rgba(38, 38, 66, 0.1);
        color: $tolopeaColor;
        font-size: $desctop13px;

        @media only screen and (max-width: 499px) {
            border: $mobile1px solid rgba(38, 38, 66, 0.1);
            font-size: $mobile13px;
        }
    }

    &__labelActive{
        color: $tolopeaColor;
    }

    &__label {
        color: $ghostColor;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }
    }

    &__error {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: -1.5vw;

        @media only screen and (max-width: 499px) {
            bottom: -5.3vw;
            align-items: flex-end;
        }

        &_icon {
            width: $desctop16px;
            height: $desctop16px;
            margin-right: $desctop10px;

            @media only screen and (max-width: 499px) {
                width: $mobile16px;
                height: $mobile16px;
                margin-right: $mobile7px;
            }

            path {
                fill:$redColor;
            }
        }

        &_text {
            font-family: $Montserrat500;
            font-size: $desctop12px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
            }
        }
    }

    &__buttonWrapper {
        display: flex;
        justify-content: center;
        margin: $desctop40px 0 2vw 4vw;

        @media only screen and (max-width: 499px) {
            margin: $mobile42px 0 0 0;
            flex-wrap: wrap;
        }

        &_leftSide {
            display: flex;

            @media only screen and (max-width: 499px) {
                width: 100%;
                justify-content: space-between;
            }
        }

        &_back,
        &_save,
        &_remove {
            @include mainBtnStyle;
            min-width: $desctop140px;
            height: $desctop40px;
            text-transform: uppercase;
            font-family: $Montserrat900;

            @media only screen and (max-width: 499px) {
                min-width: $mobile150px;
                height: $mobile44px;
            }
        }

        &_back {
            @include grayTransparentButton;
            margin-right: $desctop30px;
        }

        &_save {
            @include transparentButton;
            border-color: $redColor !important;
            color: $redColor;
        }

        &_remove {
            @include filledButton;
            margin-left: auto;

            @media only screen and (max-width: 499px) {
                margin-right: auto;
                margin-left: 0;
                margin-top: $mobile25px;
            }

            img {
                width: $desctop9px;
                height: $desctop11px;
                margin-right: $desctop12px;

                @media only screen and (max-width: 499px) {
                    width: $mobile10px;
                    height: $mobile10px;
                    margin-right: $mobile12px;
                }
            }
        }
    }
}

.spinnerWrapper {
    flex: 1;
    position: relative;
}

.spinner {
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
