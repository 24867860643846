@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.filter {
    display: flex;
    align-items: center;
    border: $desctop1px solid rgba(255, 255, 255, 0.1);
    border-radius: $desctop30px;
    width: fit-content;
    height: $desctop45px;
    position: relative;

    @media only screen and (max-width: 499px) {
        border: $mobile1px solid rgba(255, 255, 255, 0.1);
        border-radius: $mobile30px;
        height: $mobile44px;
        width: 100%;
    }

    &__allFilters {
        display: flex;
        align-items: center;
        padding-left: $desctop24px;
        border-right: $desctop1px solid rgba(255, 255, 255, 0.1);
        height: 100%;
        cursor: pointer;
        position: relative;
        min-width: 11.2vw;
        z-index: 10;
        white-space: nowrap;

        @media only screen and (max-width: 499px) {
            padding-left: $mobile15px;
            border-right: $mobile1px solid rgba(255, 255, 255, 0.1);
            width: 35%;
            min-width: inherit;
        }

        &:hover {
            .filter__allFilters_text {
                color: $redColor;
            }

            .filter__allFilters_arrowDown {
                transform: rotate(180deg);

                path {
                    fill: $redColor;
                }
            }

            .filter__allFilters_menu {
                opacity: 1;
                user-select: auto;
                pointer-events: auto;
            }
        }

        &_text {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop24px;
            color: $whiteColor;
            margin-right: auto;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile24px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &_arrowDown {
            width: $desctop8px;
            height: $desctop5px;
            margin: 0 $desctop19px;
            transition: all 0.25s;

            @media only screen and (max-width: 499px) {
                width: $mobile7px;
                height: $mobile5px;
                margin: 0 $mobile10px;
            }
        }

        &_menu {
            position: absolute;
            left: 0;
            right: 0;
            top: 3.05vw;
            background: $whiteColor;
            border: $desctop1px solid rgba(36, 36, 68, 0.1);
            border-radius: $desctop17px;
            padding: $desctop10px $desctop20px;
            min-width: 8vw;
            opacity: 0;
            user-select: none;
            pointer-events: none;

            @media only screen and (max-width: 499px) {
                border: $mobile1px solid rgba(36, 36, 68, 0.1);
                border-radius: $mobile17px;
                padding: $mobile10px;
                top: 11.8vw;
                min-width: 35%;
            }

            li {
                font-family: $Montserrat500;
                font-size: $desctop13px;
                line-height: $desctop19px;
                color: $bunkerColor;
                cursor: pointer;
                transition: color 0.25s;
                padding: $desctop4px 0;
                text-overflow: ellipsis;
                overflow: hidden;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile13px;
                    line-height: $mobile19px;
                    padding: $mobile5px 0;
                }

                &:hover {
                    color: $redColor;
                }
            }
        }
    }

    &__inputtWrapper {
        display: flex;
        position: relative;
        z-index: 10;
        height: 100%;

        @media only screen and (max-width: 499px) {
            width: 65%;
        }

        &_input {
            height: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            padding-left: $desctop19px;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop24px;
            color: $whiteColor;

            @media only screen and (max-width: 499px) {
                padding: 0 $mobile5px 0 $mobile10px;
                font-size: $mobile13px;
                line-height: $mobile24px;
            }

            &::placeholder {
                font-family: $Montserrat500;
                font-size: $desctop13px;
                line-height: $desctop24px;
                color: $whiteColor;
                opacity: 0.3;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile13px;
                    line-height: $mobile24px;
                }
            }
        }
    }
}

.search {
    display: flex;

    &__dropdown {
        position: absolute;
        left: 0;
        right: 0;
        top: 3.05vw;
        background: $whiteColor;
        border: $desctop1px solid rgba(36, 36, 68, 0.1);
        border-radius: $desctop17px;
        min-width: 8vw;
        opacity: 0;
        user-select: none;
        pointer-events: none;
        max-height: 20.6vw;
        overflow-Y: auto;

        @media only screen and (max-width: 499px) {
            border: $mobile1px solid rgba(36, 36, 68, 0.1);
            border-radius: $mobile17px;
            padding: 0;
            top: 11.8vw;
            min-width: 35%;
            max-height: 72vw;
            overflow-x: hidden;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &_wrapper {
            width: 91%;

            @media only screen and (max-width: 499px) {
                width: 83%;
            }
        }

        &_item,
        &_bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &_item {
            cursor: pointer;
            padding: $desctop10px $desctop20px;
            margin: 0 auto;

            @media only screen and (max-width: 499px) {
                padding: $mobile7px;
            }

            &:not(:last-child) {
                border-bottom: $desctop1px solid rgba(36, 36, 68, 0.1);

                @media only screen and (max-width: 499px) {
                    border-bottom: $mobile1px solid rgba(36, 36, 68, 0.1);
                }
            }

            &:hover {
                background-color: $whiteSmokeColor;
            }
        }

        &_img {
            width: $desctop30px;
            height: $desctop30px;
            margin-right: $desctop10px;

            @media only screen and (max-width: 499px) {
                width: $mobile24px;
                height: $mobile24px;
                margin-right: $mobile10px;
            }
        }

        &_address {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $tolopeaColor;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 80%;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                max-width: 81%;
            }
        }

        &_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &_type {
            font-family: $Montserrat500;
            font-size: $desctop10px;
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile19px;
                margin-right: 1vw;
            }
        }

        &_date,
        &_value {
            font-family: $Montserrat500;
            font-size: $desctop10px;
            line-height: $desctop19px;
            color: $ghostColor;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile19px;
            }
        }

        &_date {
            margin-right: $desctop10px;
            max-width: 60%;

            @media only screen and (max-width: 499px) {
                margin-right: 0.5vw;
                max-width: 100%;
            }
        }

        &_value {
            max-width: 53%;

            @media only screen and (max-width: 499px) {
               display: none;
            }
        }
    }

    &__dropdownActive {
        opacity: 1;
        user-select: auto;
        pointer-events: auto;
    }

    &__buttonBlock {
        opacity: 0.5;
        user-select: none;
        pointer-events: none;
    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: $desctop63px;

    @media only screen and (max-width: 499px) {
        min-width: 14.4vw;
        width: 30%;
    }
}

.nothingFound {
    position: absolute;
    left: $desctop19px;
    bottom: -1.1vw;
    font-family: $Montserrat500;
    font-size: $desctop11px;
    color: $redColor;

    @media only screen and (max-width: 499px) {
        left: $mobile10px;
        bottom: -4.1vw;
        font-size: $mobile10px;
    }
}
