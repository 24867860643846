@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixin';

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $desctop20px;
    max-width: 59.5vw;
    border-radius: $desctop8px;
    background: $whiteColor;
    box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);

    @media only screen and (max-width: 499px) {
        padding: 0;
        max-width: 100%;
        border-radius: initial;
        box-shadow: initial;
    }
}

.header {
    display: flex;
    align-items: center;
    border-bottom: $desctop1px solid $solitudeColor;
    padding: $desctop11px;
    margin-bottom: $desctop18px;

    @media only screen and (max-width: 499px) {
        justify-content: space-between;
        border-bottom: $mobile1px solid $solitudeColor;
        padding: 0 0 $mobile15px 0;
        margin-bottom: $mobile20px;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $tolopeaColor;
        margin-right: $desctop15px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile15px;
            line-height: $mobile26px;
            margin-right: $mobile15px;
        }
    }

    &__add {
        display: flex;
        align-items: center;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $redColor;
        cursor: pointer;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }

        &:hover {
            color: rgba(255, 48, 21, 0.5);
        }

        img {
            width: $desctop13px;
            height: $desctop13px;
            margin-right: $desctop8px;

            @media only screen and (max-width: 499px) {
                width: $mobile16px;
                height: $mobile16px;
                margin-right: $mobile7px;
            }
        }
    }
}

.totalFound {
    font-family: $Montserrat500;
    font-size: $desctop12px;
    line-height: $desctop19px;
    color: $ghostColor;
    padding: $desctop3px $desctop3px $desctop13px $desctop3px;
    box-shadow: 0 $desctop2px $desctop2px -0.14vw rgba(0, 0, 0, 0.07);
    position: relative;
    z-index: 10;

    @media only screen and (max-width: 499px) {
        border-bottom: $mobile1px solid $solitudeColor;
        box-shadow: none;
        font-size: $mobile12px;
        line-height: $mobile19px;
        padding: $mobile4px $mobile4px $mobile20px $mobile4px;
    }
}

.noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;

    @media only screen and (max-width: 499px) {
        margin: $mobile25px auto $mobile25px;
    }

    &__icon {
        width: $desctop200px;
        height: $desctop200px;

        @media only screen and (max-width: 499px) {
            width: 50vw;
            height: 50vw;
        }
    }

    &__title {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $blackColor;
        margin: $desctop20px 0;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
            margin: $mobile20px 0;
        }
    }
}

.wrapper {
    max-height: 28vw;
    overflow-Y: auto;

    &::-webkit-scrollbar {
        width: 0.2vw;
        display: block !important;
    }

    &::-webkit-scrollbar-track {
        background: $ghostColor;
    }

    &::-webkit-scrollbar-thumb {
        background: #19192d;
        border-radius: 2.5vw;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $slateGreyColor;
    }

    @media only screen and (max-width: 499px) {
        max-height: initial;
    }
}

.notifications {
    background: $whiteColor;
    box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
    border-radius: $desctop8px;
    padding: $desctop24px $desctop21px;
    margin: $desctop3px $desctop3px $desctop25px $desctop3px;

    @media only screen and (max-width: 499px) {
        box-shadow: 0 $mobile4px $mobile6px rgba(0, 0, 0, 0.07);
        border-radius: $mobile7px;
        padding: $mobile24px $mobile21px;
        margin: $mobile4px $mobile4px $mobile25px $mobile4px;
    }

    &:last-child {
        margin: $desctop3px $desctop3px 0 $desctop3px;

        @media only screen and (max-width: 499px) {
            margin: $mobile4px;
        }
    }

    &__statusWr {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile10px;
            align-items: flex-end;
        }
    }

    &__status {
        display: flex;
        align-items: flex-end;
        margin-bottom: $desctop10px;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: $mobile10px;
        }

        &_img {
            width: $desctop16px;
            height: $desctop16px;
            margin-right: $desctop15px;

            @media only screen and (max-width: 499px) {
                width: $mobile16px;
                height: $mobile16px;
                margin-right: $mobile15px;
            }
        }

        &_text {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $blackColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        &_link {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $redColor;
            margin-left: $desctop5px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                margin-left: 0;
            }

            &:hover {
                color: $redColor;
                text-decoration: underline;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-bottom: $desctop10px;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
            margin-bottom: $mobile10px;
        }
    }

    &__leftSide,
    &__rightSide {
        &_text {
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                line-height: $mobile19px;
            }

            &:not(:last-child) {
                margin-bottom: $desctop5px;

                @media only screen and (max-width: 499px) {
                    margin-bottom: $mobile5px;
                }
            }

            a,
            span {
                margin-left: $desctop5px;

                @media only screen and (max-width: 499px) {
                    margin-left: $mobile5px;
                }
            }

        }
    }

    &__leftSide {
        &_text {
            font-family: $Montserrat500;
            font-size: $desctop10px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
            }

            a {
                color: $redColor;

                &:hover {
                    color: $redColor;
                    text-decoration: underline;
                }
            }

            span {
                color: $ghostColor;
            }
        }
    }

    &__rightSide {

        @media only screen and (max-width: 499px) {
           margin-top: $mobile10px;
        }

        &_text {
            font-family: $Montserrat900;
            font-size: $desctop12px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
            }

            span {
                font-family: $Montserrat500;
                color: $tolopeaColor;
            }
        }
    }
}

.details {
    display: flex;
    flex-direction: column;
    margin: $desctop3px $desctop3px $desctop25px $desctop3px;
    background: $whiteColor;
    box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
    border-radius: $desctop8px;
    padding: $desctop14px $desctop40px $desctop14px $desctop40px;
    display: none;

    @media only screen and (max-width: 499px) {
        flex-direction: column;
        padding: $mobile20px;
        box-shadow: 0px $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
        border-radius: $mobile10px;
        margin: $mobile4px;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__arrow {
        min-width: $desctop12px;
        min-height: $desctop12px;
        margin: auto $desctop30px;

        @media only screen and (max-width: 499px) {
            min-width: $mobile12px;
            min-height: $mobile12px;
            margin: 0 auto $mobile25px;
            transform: rotate(90deg);
        }

        path {
            fill: $tolopeaColor
        }
    }

    &__rightSide {
        width: 100%;
    }

    &__labelWrapper {
        display: flex;
        align-items: center;

        .details__label {
            &:last-child {
                width: 13.5vw;

                @media only screen and (max-width: 499px) {
                    width: 37.5vw;
                }
            }
        }
    }

    &__label {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $midnightExpressColor;
        margin-bottom: $desctop11px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile14px;
            line-height: $mobile20px;
            margin-bottom: $mobile10px;
        }
    }

    &__rowWrapper {
        display: flex;
        flex-direction: column;
    }

    &__row {
        display: flex;
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        padding: $desctop10px $desctop20px;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            box-shadow: none;
            border-radius: $desctop8px;
            padding: 0;
            margin-bottom: $mobile20px;
        }

        &_staking {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        a {
            color: $redColor;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }

            &:hover {
                color: $redColor;
                text-decoration: underline;
            }
        }
    }
}

.detailsOpened {
    display: flex;
}

.dotWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        opacity: 0.8;
    }

    &__dot {
        display: flex;
        align-items: center;
        justify-content: center;

        img,svg {
            width: $desctop15px;
            height: $desctop15px;

            @media only screen and (max-width: 499px) {
                width: $mobile15px;
                height: $mobile15px;
            }
        }

        span {
            color: $redColor;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            margin-left: $desctop5px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                margin-left: $mobile5px;
            }
        }
    }
}

.removeWr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $desctop20px;

    @media only screen and (max-width: 499px) {
        margin-top: $mobile20px;
    }
}
