@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.directory {
    background: $whiteColor;
    padding: $desctop28px 6vw $desctop15px 8vw;
    flex: 1;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $midnightExpressColor;
        margin-bottom: $desctop19px;

        @media only screen and (max-width: 499px) {
            display: none;
        }
    }
}

.search {
    &__inputWrapper {
        display: flex;
        width: fit-content;
        border: $desctop1px solid rgba(31, 31, 59, 0.1);
        border-radius: $desctop30px;
        height: $desctop45px;

        @media only screen and (max-width: 499px) {
            justify-content: space-between;
            width: 100%;
            border: $mobile1px solid rgba(31, 31, 59, 0.1);
            border-radius: $mobile30px;
            height: $mobile44px;
        }
    }

    &__input {
        min-width: 40vw;
        height: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        padding-left: $desctop19px;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop24px;
        color: $midnightExpressColor;

        @media only screen and (max-width: 499px) {
            min-width: auto;
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 $mobile5px 0 $mobile10px;
            font-size: $mobile13px;
            line-height: $mobile24px;
        }

        &::placeholder {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop24px;
            color: $ghostColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile24px;
            }
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $redColor;
        border-radius: $desctop32px;
        border: none;
        height: 100%;
        outline: none;
        width: $desctop63px;
        cursor: pointer;
        transition: all 0.25s;

        @media only screen and (max-width: 499px) {
            border-radius: $mobile30px;
            width: $mobile65px;
        }

        &:hover {
            opacity: 0.5;
        }
    }

    &__searchIcon {
        width: $desctop14px;
        height: $desctop14px;

        @media only screen and (max-width: 499px) {
            height: $mobile13px;
            width: $mobile13px;
        }
    }
}

.container {
    margin: $desctop20px 0 $desctop5px;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 499px) {
        margin: $mobile20px 0 $mobile5px;
    }

    &__item {
        display: flex;
        box-shadow: 0 $desctop4px $desctop6px rgba(0, 0, 0, 0.05);
        border-radius: 0 0 $desctop8px $desctop8px;
        width: fit-content;
        margin-bottom: $desctop15px;
        width: 32%;

        @media only screen and (max-width: 499px) {
            box-shadow: 0 $mobile5px $mobile6px rgba(0, 0, 0, 0.05);
            border-radius: 0 0 $mobile10px $mobile10px;
            margin-bottom: $mobile15px;
            width: 100%;
        }

        &:not(:nth-child(3n)) {
            margin-right: $desctop20px;

            @media only screen and (max-width: 499px) {
                margin-right: 0;
            }
        }
    }

    &__leftSide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(233, 233, 236, 0.3);
        padding: $desctop14px;
        width: 45%;
        border-radius: $desctop8px 0 0 $desctop8px;

        @media only screen and (max-width: 499px) {
            padding: $mobile16px;
            border-radius: $mobile10px 0 0 $mobile10px;
        }

        &_icon {
            width: $desctop60px;
            height: $desctop60px;
            margin: 0 auto $desctop20px;
            display: block;

            @media only screen and (max-width: 499px) {
                width: $mobile60px;
                height: $mobile60px;
                margin: 0 auto $mobile20px;
            }
        }

        &_title {
            font-family: $Montserrat500;
            font-size: $desctop22px;
            line-height: $desctop24px;
            text-align: center;
            color: $tolopeaColor;
            margin-bottom: $desctop5px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile21px;
                line-height: $mobile24px;
                margin-bottom: $mobile5px;
            }
        }

        &_subTitle {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop24px;
            text-align: center;
            color: $midnightExpressColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile24px;
            }
        }
    }

    &__rightSide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $whiteColor;
        border-radius: 0 $desctop8px $desctop8px 0;
        padding: $desctop14px;
        width: 55%;

        @media only screen and (max-width: 499px) {
            padding: $mobile16px;
            border-radius: 0 $mobile10px $mobile10px 0;
        }

        &_wrapper {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: $desctop15px;

                @media only screen and (max-width: 499px) {
                    margin-bottom: $mobile15px;
                }
            }
        }

        &_icon {
            width: $desctop6px;
            height: $desctop6px;
            margin-right: $desctop6px;

            @media only screen and (max-width: 499px) {
                width: $mobile6px;
                height: $mobile6px;
                margin-right: $mobile6px;
            }

            path {
                fill: $redColor;
            }
        }

        &_link {
            font-family: $Montserrat600;
            font-size: $desctop12px;
            line-height: $desctop14px;
            letter-spacing: $desctop1px;
            text-transform: uppercase;
            color: $redColor;
            display: flex;

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile14px;
                letter-spacing: $mobile1px;
            }

            &:hover {
                color: rgba(255, 48, 21, 0.5);
            }
        }
    }
}

.foundSomethingInteresting {
    font-family: $Montserrat500;
    font-size: $desctop13px;
    line-height: $desctop19px;
    color: $tolopeaColor;

    @media only screen and (max-width: 499px) {
        font-size: $mobile13px;
        line-height: $mobile19px;
    }

    a {
        color: $redColor;

        &:hover {
            color: $redColor;
            text-decoration: underline;
        }
    }
}
