@import '../../assets/styles/variables';
@import '../../assets/styles/mixin.scss';

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: $desctop20px;
    border-radius: $desctop10px;

    &__closeIcon {
        position: absolute;
        right: 2vw;
        top: 2vw;
        cursor: pointer;
        z-index: 10;

        @media only screen and (max-width: 499px) {
            right: $mobile20px;
            top: $mobile20px;
        }

        svg {
            width: 1.2vw;
            height: 1.2vw;

            @media only screen and (max-width: 499px) {
                width: $mobile18px;
                height: $mobile18px;
            }
        }
    }
}

.showModal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
