@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.firstScreen {
    background: $blueGradient;
    height: $desctop295px;
    position: relative;

    @media only screen and (max-width: 499px) {
        height: 60vw;
    }

    &__vector1,
    &__vector2 {
        position: absolute;

        @media only screen and (max-width: 499px) {
            display: none;
        }
    }

    &__vector1 {
        right: 5vw;
        bottom: -3.5vw;
        width: 40.5vw;
        height: 26.6vw;
        z-index: 1;
    }

    &__vector2 {
        left: -2.5vw;
        bottom: 0;
        width: 23.2vw;
        height: 13.334vw;
    }

    &__vector3 {
        display: none;

        @media only screen and (max-width: 499px) {
            display: block;
            position: absolute;
            top: 0;
            z-index: 1;
            width: 100vw;
            height: 36vw;
        }
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        color: $whiteColor;
        margin: 0 0 4.5vw;

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            margin: $mobile20px 0 $mobile30px;
        }

        span {
            color: $redColor;
        }
    }

    &__container {
        padding: 0 $desctop148px $desctop27px;

        @media only screen and (max-width: 499px) {
            padding: 0 $mobile20px;
        }
    }
}

.filter {
    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $redColor;
        border-radius: $desctop32px;
        border: none;
        height: 100%;
        outline: none;
        width: $desctop63px;
        cursor: pointer;
        transition: all 0.25s;

        @media only screen and (max-width: 499px) {
            border-radius: $mobile30px;
            min-width: 14.4vw;
            width: 30%;
        }

        &:hover {
            opacity: 0.5;
        }
    }

    &__searchIcon {
        width: $desctop14px;
        height: $desctop14px;

        @media only screen and (max-width: 499px) {
            height: $mobile13px;
            width: $mobile13px;
        }
    }

    &__input {
        min-width: 30.1vw;

        @media only screen and (max-width: 499px) {
            min-width: auto;
            width: 75%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
