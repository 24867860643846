@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.comments {
    margin-top: $desctop15px;

    @media only screen and (max-width: 499px) {
        margin-top: $mobile15px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $desctop24px;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            margin-bottom: $mobile24px;
        }

        &_countComments {
            font-family: $Montserrat500;
            font-size: $desctop24px;
            line-height: $desctop34px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                width: 100%;
                font-size: $mobile24px;
                line-height: $mobile34px;
            }
        }
    }

    &__privacy {
        display: flex;
        align-items: center;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $redColor;


        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
            margin-top: $mobile10px;
        }

        img {
            width: $desctop11px;
            height: $desctop14px;
            margin-right: $desctop5px;

            @media only screen and (max-width: 499px) {
                width: $mobile10px;
                height: $mobile14px;
                margin-right: $mobile5px;
            }
        }
    }
}

.messages {
    display: flex;
    align-items: flex-start;
    margin-bottom: $desctop34px;

    @media only screen and (max-width: 499px) {
        margin-bottom: $mobile30px;
    }

    &__logo {
        width: $desctop50px;
        height: $desctop50px;

        @media only screen and (max-width: 499px) {
            width: $mobile50px;
            height: $mobile50px;
        }
    }

    &__leftSide {
        margin-left: $desctop30px;
        max-width: 44vw;

        @media only screen and (max-width: 499px) {
            margin-left: $mobile20px;
            max-width: initial;
        }

        &_header {
            border-bottom: $desctop1px solid $gainsboroColor;
            margin-bottom: $desctop8px;

            @media only screen and (max-width: 499px) {
                display: flex;
                flex-wrap: wrap;
                border-bottom: $mobile1px solid $gainsboroColor;
                margin-bottom: $mobile7px;
            }
        }

        &_headerName,
        &_headerDate {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            letter-spacing: $desctop1px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                letter-spacing: $mobile1px;
                width: 100%;
            }
        }

        &_headerName {
            color: $blackColor;
            margin-right: $desctop21px;

            @media only screen and (max-width: 499px) {
                margin-right: 0;
            }
        }

        &_headerDate {
            color: #5F5F5F;
        }

        &_message {
            font-family: $Montserrat400;
            font-size: $desctop14px;
            line-height: $desctop22px;
            letter-spacing: $desctop1px;
            color: $blackColor;


            @media only screen and (max-width: 499px) {
                font-size: $mobile14px;
                line-height: $mobile21px;
                letter-spacing: $mobile1px;
            }
        }

        &_signIn {
            display: flex;
            align-items: center;
            font-family: $Montserrat400;
            font-size: $desctop14px;
            line-height: $desctop22px;
            letter-spacing: $desctop1px;
            color: $blackColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile14px;
                line-height: $mobile21px;
                letter-spacing: $mobile1px;
            }
        }

        &_button {
            @include filledButton;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            min-width: $desctop140px;
            height: $desctop45px;
            font-family: $Montserrat900;
            font-size: $desctop12px;
            margin-left: $desctop32px;

            &:hover {
                color: $whiteColor;
            }

            @media only screen and (max-width: 499px) {
                height: $mobile44px;
                font-size: $mobile12px;
                min-width: 34vw;
            }
        }
    }
}
