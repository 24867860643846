@import '../.././assets/styles/variables';

.loading {
    display: flex;
    align-items: center;
    justify-items: center;
    flex: 1;
    background: $zirconColor;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999999;

    img {
        margin: 0 auto;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 30%;
        }

        @media only screen and (max-width: 499px) {
            width: 50vw;
        }
    }
}
