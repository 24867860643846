@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.allBlocks {
    background: $whiteColor;
    padding: $desctop45px 6vw $desctop30px 8vw;
    flex: 1;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $midnightExpressColor;
        margin-bottom: $desctop35px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile24px;
            margin: 0 0 $mobile30px 0;
            width: 100%;
        }
    }
}

.table {
    margin-bottom: $desctop30px;

    @media only screen and (max-width: 499px) {
        margin-bottom: $mobile30px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        padding: $desctop10px $desctop20px;
        margin: 0 $desctop5px $desctop15px;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            box-shadow: 0 $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
            border-radius: $mobile7px;
            padding: $mobile10px $mobile20px;
            margin: 0 $mobile5px $mobile15px;
        }
    }

    &__label {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $slateGreyColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }
    }

    &__link {
        @include text500red;
    }

    &__text {
        @include text500tolopeaColor;
    }

    &__column1,
    &__column2,
    &__column3,
    &__column4,
    &__column5,
    &__column6,
    &__column7 {
        &>* {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: initial;
        }
    }

    &__column1 {
        width: 5vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            margin-bottom: $mobile15px;
            order: 1;
        }
    }

    &__column2 {
        width: 9vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            margin-bottom: $mobile15px;
            order: 2;
        }
    }

    &__column3 {
        width: 5vw;

        @media only screen and (max-width: 499px) {
            width: 30%;
            order: 3;
        }
    }

    &__column4 {
        width: 26vw;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-bottom: $mobile15px;
            order: 5;
        }
    }

    &__column5 {
        width: 14vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            margin-bottom: $mobile15px;
            order: 4;
        }
    }
}

.notFoundIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: $desctop30px 0;
    min-height: 70vh;

    @media only screen and (max-width: 499px) {
        margin: $mobile30px 0;
    }

    img {
        width: 17.5vw;
        height: 16vw;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            width: 60vw;
            height: 52vw;
            margin-bottom: $mobile20px;
        }
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $blackColor;
        margin-bottom: $desctop17px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile24px;
            margin-bottom: 0;
            text-align: center;
        }
    }
}
