@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.topStatistics {
    background: $whiteColor;
    padding: $desctop45px 6vw $desctop30px 8vw;
    flex: 1;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $midnightExpressColor;
        margin-bottom: $desctop30px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile24px;
            margin: 0 0 $mobile15px 0;
            width: 100%;
        }
    }
}

.filter {
    display: flex;
    align-items: center;

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(119, 131, 143, 0.2);
        border-radius: $desctop32px;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $tolopeaColor;
        height: $desctop36px;
        min-width: $desctop80px;
        padding: $desctop8px $desctop12px;
        cursor: pointer;
        transition: all 0.4s;
        margin-bottom: $desctop23px;

        @media only screen and (max-width: 499px) {
            border-radius: $mobile32px;
            font-size: $mobile13px;
            line-height: $mobile19px;
            height: $mobile34px;
            min-width: $mobile75px;
            padding: $mobile7px $mobile12px;
            margin-bottom: $mobile24px;
        }

        &:not(:last-child) {
            margin-right: $desctop10px;

            @media only screen and (max-width: 499px) {
                margin-right: $mobile10px;
            }
        }

        &:hover {
            color: $redColor;
            background-color: rgba(255, 41, 17, 0.2);
        }
    }

    &__itemActive {
        color: $redColor;
        background-color: rgba(255, 41, 17, 0.2);
    }
}

.filterLoading {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
}

.overView {
    margin: 0 $desctop5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: 499px) {
        flex-direction: column;
    }

    &__item {
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.1);
        border-radius: $desctop8px;
        padding: $desctop17px $desctop32px;
        width: 48%;
        margin-bottom: $desctop10px;

        @media only screen and (max-width: 499px) {
            box-shadow: 0 $mobile2px $mobile6px rgba(0, 0, 0, 0.1);
            border-radius: $mobile7px;
            padding: $mobile17px $mobile32px;
            width: 100%;
            margin-bottom: $mobile10px;
        }

        &_top {
            display: flex;
            justify-content: space-between;
        }

        &_bottom {
            display: flex;
            justify-content: space-between;
        }

        &_label {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $blackColor;
            margin-bottom: $desctop5px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                margin-bottom: $mobile5px;
            }
        }

        &_value {
            font-family: $Montserrat500;
            font-size: $desctop12px;
            line-height: $desctop19px;
            color: $blackColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile19px;
            }
        }

        &_address {
            font-family: $Montserrat500;
            font-size: $desctop12px;
            line-height: $desctop19px;
            color: $redColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile19px;
            }

            &:hover {
                color: $redColor;
                text-decoration: underline;
            }
        }
    }
}

.table {
    margin-bottom: $desctop30px;

    @media only screen and (max-width: 499px) {
        margin-bottom: $mobile30px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        padding: $desctop10px $desctop20px;
        margin: 0 $desctop5px $desctop15px;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            box-shadow: 0 $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
            border-radius: $mobile7px;
            padding: $mobile10px $mobile20px;
            margin: 0 $mobile5px $mobile15px;
        }
    }

    &__label {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $slateGreyColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }
    }

    &__link {
        @include text500red;
    }

    &__text {
        @include text500tolopeaColor;
    }

    &__column1,
    &__column2,
    &__column3,
    &__column4,
    &__column5,
    &__column6,
    &__column7 {
        &>* {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: initial;
        }
    }

    &__column1 {
        width: 4vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            margin-bottom: $mobile15px;
            order: 1;
        }
    }

    &__column2 {
        width: 23vw;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-bottom: $mobile15px;
            order: 6;
        }
    }

    &__column3 {
        width: 13vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            order: 2;
        }
    }

    &__column4 {
        width: 10vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            margin-bottom: $mobile15px;
            order: 5;
        }
    }

    &__column5 {
        width: 12vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            margin-bottom: $mobile15px;
            order: 4;
        }
    }

    &__column6 {
        width: 10vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            margin-bottom: $mobile15px;
            order: 6;
        }
    }
}

.transactions {
    margin-bottom: $desctop30px;

    @media only screen and (max-width: 499px) {
        margin-bottom: $mobile30px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        padding: $desctop10px $desctop20px;
        margin: 0 $desctop5px $desctop15px;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            box-shadow: 0 $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
            border-radius: $mobile7px;
            padding: $mobile10px $mobile20px;
            margin: 0 $mobile5px $mobile15px;
        }
    }

    &__label {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $slateGreyColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }
    }

    &__link {
        @include text500red;
    }

    &__text {
        @include text500tolopeaColor;
    }

    &__column1,
    &__column2,
    &__column3,
    &__column4 {
        &>* {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: initial;
        }
    }

    &__column1 {
        width: 7vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            margin-bottom: $mobile15px;
            order: 1;
        }
    }

    &__column2 {
        width: 25vw;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-bottom: $mobile15px;
            order: 6;
        }
    }

    &__column3 {
        width: 13vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            order: 2;
        }
    }

    &__column4 {
        width: 10vw;

        @media only screen and (max-width: 499px) {
            width: 45%;
            margin-bottom: $mobile15px;
            order: 5;
        }
    }
}

.container {
    &__title {
        font-family: $Montserrat500;
        font-size: $desctop16px;
        line-height: $desctop24px;
        color: $blackColor;
        margin-bottom: $desctop18px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile24px;
            margin-bottom: $mobile18px;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &_item {
            width: 48%;
            margin-bottom: $desctop20px;

            @media only screen and (max-width: 499px) {
                width: 100%;
                margin-bottom: $mobile20px;
            }
        }
    }
}

.spinnerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw 0 5vw;

    @media only screen and (max-width: 499px) {
        padding: 5vw 0;
    }

    img {
        width: $desctop200px;
        height: $desctop200px;

        @media only screen and (max-width: 499px) {
            width: $mobile150px;
            height: $mobile150px;
        }
    }
}

.noData {
    font-family: $Montserrat500;
    font-size: $desctop16px;
    line-height: $desctop20px;
    color: $midnightExpressColor;

    @media only screen and (max-width: 499px) {
        font-size: $mobile16px;
        line-height: $mobile24px;
    }
}

.network {
    display: flex;
    justify-content: space-between;
    margin-top: $desctop30px;

    @media only screen and (max-width: 499px) {
       flex-direction: column;
    }

    &__leftSide,
    .table {
        width: 48%;

        @media only screen and (max-width: 499px) {
            width: 100%;
        }
    }

    &__leftSide {
        margin:  0 0 $desctop5px $desctop8px;
        padding: $desctop30px 0;
        height: fit-content;
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.1);
        border-radius: $desctop13px;

        @media only screen and (max-width: 499px) {
            margin: 0 $mobile5px $mobile15px;
            width: auto;
            padding: $mobile20px 0 0;
            box-shadow: 0 $mobile2px $mobile6px rgba(0, 0, 0, 0.1);
            border-radius: $mobile13px;
        }
    }
}
