@import '../../../../assets/styles/variables';

.aside {
    margin-right: $desctop15px;
    min-width: 24vw;
    background: $whiteColor;
    box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
    border-radius: $desctop8px;

    @media only screen and (max-width: 499px) {
        display: none;
    }

    &__top {
        padding: $desctop25px;
    }

    &__bottom {
        padding: $desctop25px 0;
    }

    &__top {
        display: flex;
        align-items: center;
        background-image: url('./images/profile-background.svg');
        background-size: cover;
        background-position: 0vw -4vw;
        background-repeat: no-repeat;

        &_logo {
            border-radius: 50%;
            border: $desctop5px solid $whiteColor;
            width: $desctop70px;
            height: $desctop70px;
            object-fit: cover;
        }

        &_noLogo {
            span {
                font-size: 1.4vw;
            }
        }

        &_right {
            margin-left: $desctop20px;
        }

        &_network {
            font-family: $Montserrat600;
            font-size: $desctop10px;
            line-height: $desctop19px;
            color: $redColor;
            text-transform: uppercase;
        }

        &_name {
            font-family: $Montserrat500;
            font-size: $desctop24px;
            line-height: $desctop34px;
            color: $midnightExpressColor;
            max-width: 14vw;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }
    }

    &__linksWrapper {
        display: flex;
        flex-direction: column;
    }

    &__link {
        display: flex;
        align-items: center;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $tolopeaColor;
        cursor: pointer;
        padding: $desctop15px;

        &:hover {
            color: $redColor;
        }

        img {
            width: $desctop20px;
            height: $desctop20px;
            margin-right: $desctop15px;
        }
    }
}
