@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.info {
    margin: $desctop15px 0;

    @media only screen and (max-width: 499px) {
        margin: $mobile15px 0;
    }

    &__header {
        font-family: $Montserrat900;
        font-size: $desctop12px;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        color: $tolopeaColor;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile12px;
            letter-spacing: $mobile1px;
            margin-bottom: $mobile20px;
        }
    }

    &__content {
        @include text500tolopeaColor;
        line-height: $desctop22px;
        letter-spacing: $desctop1px;

        @media only screen and (max-width: 499px) {
            line-height: $mobile21px;
            letter-spacing: $mobile1px;
        }
    }
}
