@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.block {
    background: $whiteColor;
    padding: $desctop45px 6vw $desctop15px 8vw;
    flex: 1;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $midnightExpressColor;
        margin-bottom: $desctop19px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile24px;
            margin-bottom: $mobile15px;
        }
    }

    &__subTitle {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $bunkerColor;
        margin-bottom: $desctop28px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
            margin-bottom: $mobile20px;
        }

        a {
            color: $redColor;

            &:hover {
                color: $redColor;
                text-decoration: underline;
            }
        }
    }
}

.totalOutput__leftSide,
.privateNote,
.rawBlock,
.transBlock,
.logs {
    padding: $desctop20px;
    background: $whiteColor;
    box-shadow: 0px $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
    border-radius: $desctop8px;
    margin: 0 $desctop3px $desctop15px;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
        box-shadow: 0px $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
        border-radius: $mobile10px;
        margin-bottom: $mobile15px;
    }
}

.totalOutput {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__leftSide {
        display: flex;
        flex-direction: column;
        width: 49%;

        @media only screen and (max-width: 499px) {
            width: 100%;
        }
    }

    &__top {
        display: flex;
        align-items: center;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $midnightExpressColor;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            font-size: $mobile12px;
            line-height: $mobile18px;
        }

        &_amount {
            font-size: $desctop24px;
            line-height: $desctop34px;
            margin: 0 $desctop10px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile24px;
                line-height: $mobile34px;
                margin: 0;
            }
        }

        &_icon {
            width: $desctop16px;
            height: $desctop16px;
            margin: 0 $desctop5px 0 0;
            object-fit: scale-down;

            @media only screen and (max-width: 499px) {
                width: $mobile16px;
                height: $mobile16px;
                margin: 0 $mobile5px 0 0;
            }
        }

        &_success {
            color: $redColor;
            font-size: $desctop13px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
            }
        }

        &_pending {
            color: $slateGreyColor;
            font-size: $desctop13px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
            }
        }

        &_totalOutput {
            @media only screen and (max-width: 499px) {
                width: 100%;
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        margin-top: $desctop10px;
        font-family: $Montserrat500;
        font-size: $desctop10px;
        line-height: $desctop16px;
        color: $slateGreyColor;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            margin-top: $mobile10px;
            font-size: $mobile10px;
            line-height: $mobile16px;
        }

        span {
            margin-right: $desctop16px;

            @media only screen and (max-width: 499px) {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}

.transBlock {

    &__hash,
    &__block,
    &__timestamp {
        display: flex;
        align-items: center;
        margin-bottom: $desctop15px;

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile20px;
            flex-wrap: wrap;
        }
    }

    &__timestamp {
        margin-bottom: 0;
    }

    &__label,
    &__hash {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $midnightExpressColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }
    }

    &__label {
        min-width: 14.1vw;
        margin-right: $desctop50px;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin: 0 0 $mobile5px 0;
        }
    }

    &__hashWrapper {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 499px) {
            width: 100%;
            justify-content: space-between;
        }

        p {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $midnightExpressColor;

            @media only screen and (max-width: 499px) {
                max-width: 72vw;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }
    }

    &__copy {
        box-sizing: content-box;
        display: flex;

        svg {
            padding: 0 $desctop10px;
            cursor: pointer;
            width: $desctop12px;
            height: $desctop12px;

            @media only screen and (max-width: 499px) {
                padding: 0 0 0 $mobile10px;
                width: $mobile12px;
                height: $mobile12px;
            }

            &:hover {
                opacity: 0.5;
            }
        }
    }

    &__block {
        &_blockNumber {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $redColor;
            margin-right: $desctop5px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                margin-right: $mobile5px;
            }

            &:hover {
                color: $redColor;
                text-decoration: underline;
            }
        }

        &_forked {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $midnightExpressColor;
            margin-right: $desctop5px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                margin-right: $mobile5px;
            }
        }

        &_confirmations {
            background: rgba(119, 131, 143, 0.1);
            border-radius: $desctop4px;
            padding: $desctop2px $desctop6px;
            margin-left: $desctop5px;
            font-family: $Montserrat500;
            font-size: $desctop10px;
            line-height: $desctop17px;
            letter-spacing: $desctop1px;
            color: $slateGreyColor;

            @media only screen and (max-width: 499px) {
                border-radius: $mobile5px;
                padding: $mobile2px $mobile6px;
                margin-left: $mobile5px;
                font-size: $mobile10px;
                line-height: $mobile17px;
                letter-spacing: $mobile1px;
            }
        }
    }

    &__timestampWrapper {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
        }

        &_left,
        &_right {
            display: flex;
            align-items: center;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                width: 100%;
            }

            svg {
                width: $desctop14px;
                height: $desctop14px;
                min-width: $desctop14px;

                @media only screen and (max-width: 499px) {
                    width: $mobile14px;
                    height: $mobile14px;
                    min-width: $mobile14px;
                }
            }
        }

        &_left {
            color: $midnightExpressColor;
            margin-right: 5.5vw;
            width: 18.7vw;
            white-space: nowrap;

            @media only screen and (max-width: 499px) {
                margin: 0 0 $mobile10px 0;
                width: 100%;
            }

            svg {
                margin-right: $desctop5px;

                @media only screen and (max-width: 499px) {
                    margin-right: $mobile5px;
                }
            }
        }

        &_right {
            color: $slateGreyColor;

            svg {
                margin-right: $desctop8px;

                @media only screen and (max-width: 499px) {
                    margin-right: $mobile5px;
                }

                path {
                    fill: $slateGreyColor;
                }
            }
        }
    }
}

.privateNote {
    display: flex;
    flex-direction: column;
    width: 49%;
    height: auto;
    font-family: $Montserrat500;
    font-size: $desctop13px;
    line-height: $desctop19px;
    color: $midnightExpressColor;
    padding-top: 1.8vw;

    @media only screen and (max-width: 499px) {
        flex-wrap: wrap;
        font-size: $mobile12px;
        line-height: $mobile18px;
        width: 100%;
        padding: $mobile20px;
    }

    &__title {
        white-space: nowrap;
        margin-bottom: 1vw;

        @media only screen and (max-width: 499px) {
            margin: 0 0 2vw 0;
            width: 100%;
        }
    }

    a {
        color: $redColor;

        &:hover {
            color: $redColor;
            text-decoration: underline;
        }
    }
}

.details {
    display: flex;
    flex-direction: column;
    margin: $desctop3px;
    background: $whiteColor;
    box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
    border-radius: $desctop8px;
    padding: $desctop14px $desctop40px $desctop14px $desctop40px;

    @media only screen and (max-width: 499px) {
        flex-direction: column;
        padding: $mobile20px;
        box-shadow: 0px $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
        border-radius: $mobile10px;
        margin: $mobile4px;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__arrow {
        min-width: $desctop12px;
        min-height: $desctop12px;
        margin: 2.8vw 2vw 0;

        @media only screen and (max-width: 499px) {
            min-width: $mobile12px;
            min-height: $mobile12px;
            margin: 0 auto $mobile25px;
            transform: rotate(90deg);
        }

        path {
            fill: $tolopeaColor
        }
    }

    &__rightSide,
    &__leftSide {
        width: 100%;
    }

    &__labelWrapper {
        display: flex;
        align-items: center;

        .details__label {
            &:last-child {
                margin-left: auto;
                width: 13.5vw;

                @media only screen and (max-width: 499px) {
                    width: 37.5vw;
                }
            }
        }
    }

    &__label {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $midnightExpressColor;
        margin-bottom: $desctop11px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile14px;
            line-height: $mobile20px;
            margin-bottom: $mobile10px;
        }
    }

    &__rowWrapper {
        display: flex;
        flex-direction: column;
    }

    &__row {
        display: flex;
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        padding: $desctop10px $desctop20px;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            box-shadow: none;
            border-radius: $desctop8px;
            padding: 0;
            margin-bottom: $mobile20px;
        }

        &_staking {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        a {
            color: $redColor;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }

            &:hover {
                color: $redColor;
                text-decoration: underline;
            }
        }

        p {
            color: $tolopeaColor;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        &_value {
            display: flex;
            align-items: center;
            margin-left: auto;
            width: 12vw;

            @media only screen and (max-width: 499px) {
                width: 37vw;
            }

            img {
                width: $desctop10px;
                height: $desctop10px;
                margin-right: $desctop8px;

                @media only screen and (max-width: 499px) {
                    min-width: $mobile10px;
                    min-height: $mobile10px;
                    margin-right: $mobile6px;
                }
            }

            p {
                font-family: $Montserrat500;
                font-size: $desctop12px;
                line-height: $desctop17px;
                color: $slateGreyColor;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile12px;
                    line-height: $mobile17px;
                    white-space: nowrap;
                }
            }
        }
    }

    &__total {
        display: flex;
        justify-content: space-between;
        margin-top: $desctop20px;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }

        p {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-family: $Montserrat500;
            font-size: $desctop10px;
            line-height: $desctop17px;
            letter-spacing: $desctop1px;
            color: $slateGreyColor;
            background: rgba(119, 131, 143, 0.1);
            border-radius: $desctop4px;
            padding: $desctop5px $desctop8px;
            width: fit-content;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile17px;
                letter-spacing: $mobile1px;
                border-radius: $mobile5px;
                padding: $mobile5px $mobile7px;
                margin-left: 0;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
                justify-content: center;

                &:not(:last-child) {
                    margin-bottom: $mobile20px;
                }
            }
        }

        img {
            width: $desctop9px;
            height: $desctop9px;
            margin-right: $desctop5px;

            @media only screen and (max-width: 499px) {
                width: $mobile10px;
                height: $mobile10px;
                margin-right: $mobile5px;
            }
        }
    }
}

.rawBlock {
    &__wrapper {
        border: $desctop1px solid rgba(36, 36, 68, 0.1);
        border-radius: $desctop17px;
        padding: $desctop15px $desctop19px;

        @media only screen and (max-width: 499px) {
            border: $mobile1px solid rgba(36, 36, 68, 0.1);
            border-radius: $mobile17px;
            padding: $mobile15px $mobile19px;
        }

        pre {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $slateGreyColor;

            &::-webkit-scrollbar {
                display: none;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }
    }
}

.logs {
    &__title {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $tolopeaColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile18px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: $desctop15px;

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile15px;
        }
    }

    &__wrapper {
        display: flex;

        &_item {
            display: flex;

            &:not(:last-child) {
                margin-bottom: $desctop16px;

                @media only screen and (max-width: 499px) {
                    margin-bottom: $mobile16px;
                }
            }
        }

        &_label {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $tolopeaColor;
            margin-right: $desctop32px;
            min-width: 4vw;

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile18px;
                margin-right: $mobile20px;
                min-width: 13vw;
            }
        }
    }

    &__left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $desctop40px;
        height: $desctop40px;
        background: rgba(255, 41, 17, 0.15);
        border-radius: $desctop50px;
        margin-right: $desctop40px;

        @media only screen and (max-width: 499px) {
            display: none;
            width: $mobile42px;
            height: $mobile42px;
            border-radius: $mobile50px;
            margin: 0 0 0 $mobile17px;
        }

        span {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $redColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        &_mobile {
            display: none;

            @media only screen and (max-width: 499px) {
                display: flex;
            }
        }
    }

    &__red,
    &__green,
    &__link,
    &__black,
    &__dec {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile18px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            max-width: 43vw;
        }
    }

    &__red {
        color: $redColor;
    }

    &__black {
        color: $tolopeaColor;
    }

    &__dec {
        color: $tolopeaColor;
        margin-right: $desctop8px;

        @media only screen and (max-width: 499px) {
            margin-right: $mobile7px;
        }
    }

    &__link {
        color: $redColor;

        &:hover {
            color: $redColor;
            text-decoration: underline;
        }
    }

    &__green {
        color: $greenColor;
    }

    &__index {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: $desctop22px;
        height: $desctop22px;
        background: $zirconColor;
        border-radius: 50%;
        font-family: $Montserrat400;
        font-size: $desctop10px;
        color: $tolopeaColor;
        margin-right: $desctop8px;

        @media only screen and (max-width: 499px) {
            width: $mobile21px;
            height: $mobile21px;
            font-size: $mobile10px;
            margin-right: $mobile7px;
        }
    }

    &__topicItem {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: $desctop8px;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile7px;
            }
        }
    }

    &__data {
        width: 100%;
        border: $desctop1px solid rgba(36, 36, 68, 0.1);
        border-radius: $desctop17px;
        padding: $desctop20px;

        @media only screen and (max-width: 499px) {
            border: $mobile1px solid rgba(36, 36, 68, 0.1);
            border-radius: $mobile17px;
            padding: $mobile20px;
        }

        &_tokens {
            font-family: $Montserrat400;
            font-size: $desctop13px;
            color: $slateGreyColor;
            margin-right: $desctop5px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                margin-right: $mobile5px;
            }
        }
    }
}

.state {
    &__item {
        display: flex;
        padding: $desctop20px;
        background: $whiteColor;
        box-shadow: 0px $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        margin-bottom: $desctop15px;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            padding: $mobile20px;
            box-shadow: 0px $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
            border-radius: $mobile10px;
            margin-bottom: $mobile15px;
        }

        &:not(:last-child) {
            margin-bottom: $desctop15px;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile15px;
            }
        }
    }

    &__label {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $midnightExpressColor;
        margin-bottom: $desctop10px;
        height: $desctop19px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: initial;
        }
    }

    &__addressItem,
    &__beforeItem,
    &__afterItem,
    &__stateItem {
        @media only screen and (max-width: 499px) {
            width: 100%;
            max-width: 100%;
        }
    }

    &__addressItem,
    &__beforeItem,
    &__afterItem {
        margin-right: $desctop25px;

        @media only screen and (max-width: 499px) {
            margin: 0 0 $mobile18px 0;
        }
    }

    &__addressItem {
        min-width: 27vw;

        &_value {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $blackColor;
            max-width: 27vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                max-width: 100%;
            }
        }
    }

    &__beforeItem {
        min-width: 17.3vw;

        &_value {
            display: block;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $blackColor;
            max-width: 18vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                max-width: 100%;
            }
        }

        &_more {
            display: flex;
            align-items: center;
            margin-left: $desctop40px;
            color: $redColor;
            cursor: pointer;

            @media only screen and (max-width: 499px) {
                margin-left: $mobile42px;
            }

            &:hover {
                text-decoration: underline;
            }

            svg {
                width: $desctop10px;
                height: $desctop10px;
                margin-left: $desctop4px;

                @media only screen and (max-width: 499px) {
                    width: $mobile10px;
                    height: $mobile10px;
                    margin-left: $mobile5px;
                }
            }
        }
    }

    &__afterItem {
        min-width: 18vw;
    }

    &__stateItem {
        &_value {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $blackColor;
            max-width: 14vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
                max-width: 100%;
            }
        }
    }

    &__gray {
        font-family: $Montserrat500;
        font-size: $desctop10px;
        line-height: $desctop17px;
        letter-spacing: $desctop1px;
        color: $slateGreyColor;

        @media only screen and (max-width: 499px) {
            font-size: $mobile10px;
            line-height: $mobile17px;
            letter-spacing: $mobile1px;
        }
    }
}

.notFoundIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: $desctop30px 0;
    min-height: 70vh;

    @media only screen and (max-width: 499px) {
        margin: $mobile30px 0;
    }

    img {
        width: 17.5vw;
        height: 16vw;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            width: 60vw;
            height: 52vw;
            margin-bottom: $mobile20px;
        }
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $blackColor;
        margin-bottom: $desctop17px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile24px;
            margin-bottom: 0;
            text-align: center;
        }
    }
}

.addPrivateNote {
    display: flex;
    align-items: center;
    height: fit-content;
    font-family: $Montserrat500;
    font-size: $desctop13px;
    line-height: $desctop19px;
    color: $redColor;
    white-space: nowrap;

    @media only screen and (max-width: 499px) {
        font-size: $mobile13px;
        line-height: $mobile19px;
        margin-top: 2vw;
    }

    &:hover {
        color: $redColor;
        text-decoration: underline;
    }

    img {
        width: $desctop15px;
        height: $desctop14px;
        margin-right: $desctop10px;

        @media only screen and (max-width: 499px) {
            width: $mobile14px;
            height: $mobile14px;
            margin-right: $mobile10px;
        }
    }
}

.tagInfoWrapper {
    display: flex;

    @media only screen and (max-width: 499px) {
        flex-wrap: wrap;
    }

    &__tag {
        word-break: break-all;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $tolopeaColor;
        margin-right: $desctop20px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
            margin-right: 0;
            max-width: 82%;
            overflow-wrap: break-word;
            max-width: 100%;
            width: 100%;
        }
    }
}

.noData {
    &__value {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $ghostColor;
        white-space: nowrap;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            line-height: $mobile19px;
        }
    }

    .details__label {
        margin-right: auto;
        margin-left: initial !important;
    }
}
