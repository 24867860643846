@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.contract {
    background: $whiteColor;
    padding: $desctop45px 6vw $desctop30px 8vw;
    flex: 1;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
    }

    &__header {
        display: flex;
        align-items: baseline;
        margin-bottom: $desctop35px;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
            margin-bottom: $mobile18px;
        }

        &_address {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            color: $redColor;
            margin-right: $desctop15px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile24px;
                margin-right: $mobile7px;
                max-width: 74vw;
                margin-right: auto;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $midnightExpressColor;
        margin-right: $desctop14px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile24px;
            margin: 0 0 $mobile30px 0;
            width: 100%;
        }
    }

    &__copyIcon,
    &__qrCodeIcon {
        display: flex;
        width: $desctop12px;
        height: $desctop12px;
        cursor: pointer;

        svg, img {
            width: $desctop12px;
            height: $desctop12px;
        }

        @media only screen and (max-width: 499px) {
            width: $mobile15px;
            height: $mobile15px;

            svg, img {
                width: $mobile15px;
                height: $mobile15px;
            }
        }
    }

    &__qrCodeIcon {
        margin-left: $desctop10px;

        @media only screen and (max-width: 499px) {
            margin-left: 5vw;
        }
    }
}

.topBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: $desctop35px;

    @media only screen and (max-width: 499px) {
        flex-direction: column;
        margin-bottom: $mobile15px;
    }

    &__item {
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        padding: $desctop20px;
        width: 49%;

        @media only screen and (max-width: 499px) {
            box-shadow: 0 $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
            border-radius: $mobile7px;
            padding: $mobile20px;
            width: 100%;
            margin-bottom: $mobile15px;
        }
    }

    &__row {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 499px) {
            flex-wrap: wrap;
        }

        &:not(:last-child) {
            margin-bottom: $desctop24px;

            @media only screen and (max-width: 499px) {
                margin-bottom: $mobile15px;
            }
        }
    }

    &__label {
        @include text500tolopeaColor;
        min-width: 12vw;

        @media only screen and (max-width: 499px) {
            min-width: 40vw;
            line-height: $mobile30px;
        }
    }

    &__blackText {
        @include text500tolopeaColor;

        @media only screen and (max-width: 499px) {
            line-height: $mobile30px;
        }
    }

    &__link {
        @include text500red;

        @media only screen and (max-width: 499px) {
            line-height: $mobile30px;
        }
    }

    &__contractCreator {
        display: flex;
        align-items: center;
        white-space: nowrap;

        a {
            max-width: 11.5vw;
            overflow: hidden;
            text-overflow: ellipsis;

            @media only screen and (max-width: 499px) {
                max-width: 33vw;
            }
        }
    }
}

.modal {
    position: relative;
    width: 23vw;
    padding: 1vw 0;

    @media only screen and (max-width: 499px) {
        width: 90vw;
        padding: $mobile15px 0;
    }

    canvas {
        width: 12vw !important;
        height: 12vw !important;
        margin: 0 auto;
        display: flex;

        @media only screen and (max-width: 499px) {
            width: 50vw !important;
            height: 50vw !important;
        }
    }

    &__title {
        font-family: $Montserrat500;
        font-size: $desctop24px;
        line-height: $desctop34px;
        color: $midnightExpressColor;
        text-align: center;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile34px;
            margin-bottom: $mobile20px;
        }
    }

    &__address {
        font-family: $Montserrat400;
        font-size: $desctop13px;
        color: $redColor;
        padding: $desctop25px 0 0;
        cursor: pointer;
        text-align: center;

        @media only screen and (max-width: 499px) {
            font-size: $mobile13px;
            padding: $mobile25px 0 0;
        }
    }

    &__button {
        @include mainBtnStyle;
        @include filledButton;
        font-family: $Montserrat900;
        min-width: $desctop148px;
        width: fit-content;
        height: $desctop45px;
        text-transform: uppercase;
        padding: 0 $desctop15px;
        margin: $desctop25px auto 0;

        @media only screen and (max-width: 499px) {
            min-width: 35vw;
            height: $mobile44px;
            padding: 0 $mobile15px;
            margin: $mobile25px auto 0;
        }

        &:hover {
            color: $whiteColor;
        }
    }
}
