@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.latest {
    display: flex;
    justify-content: space-between;
    padding: $desctop45px 8vw $desctop30px;
    background-color: rgba(247, 248, 248, 0.45);

    @media only screen and (max-width: 499px) {
        flex-direction: column;
        padding: 0;
    }

    &__column {
        width: 48.5%;

        @media only screen and (max-width: 499px) {
            width: 100%;
            margin-bottom: $mobile30px;
        }

        &_title {
            font-family: $Montserrat900;
            font-size: $desctop20px;
            line-height: $desctop26px;
            color: $midnightExpressColor;
            padding-left: $desctop20px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile20px;
                line-height: $mobile26px;
                padding-left: 0;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $desctop15px;

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile20px;
            padding: 0 $mobile20px;
        }

        &_button {
            @include mainBtnStyle;
            @include filledButton;
            min-width: $desctop100px;
            width: fit-content;
            height: $desctop30px;
            text-transform: uppercase;
            padding: 0 $desctop15px;

            @media only screen and (max-width: 499px) {
                min-width: 26.14vw;
                height: $mobile30px;
                white-space: nowrap;
                padding: 0 4.1vw;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        &_buttonIcon {
            width: $desctop6px;
            height: $desctop6px;
            margin-right: $desctop4px;

            @media only screen and (max-width: 499px) {
                width: $mobile6px;
                height: $mobile6px;
                margin-right: $mobile4px;
            }
        }
    }
}

.card {
    background: $whiteColor;
    box-shadow: 0px $desctop4px $desctop6px rgba(0, 0, 0, 0.05);
    border-radius: $desctop8px;
    padding: $desctop12px $desctop14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 499px) {
        box-shadow: 0 $mobile4px $mobile6px rgba(0, 0, 0, 0.05);
        border-radius: $mobile7px;
        padding: $mobile16px $mobile20px $mobile20px $mobile20px;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &:not(:last-child) {
        margin-bottom: $desctop15px;

        @media only screen and (max-width: 499px) {
            margin-bottom: $mobile15px;
        }
    }

    &__logo {
        width: $desctop40px;
        height: $desctop40px;
        margin-right: $desctop13px;

        @media only screen and (max-width: 499px) {
            width: $mobile42px;
            height: $mobile42px;
            margin-right: $mobile13px;
            order: 0;
        }
    }

    &__item {
        margin-right: $desctop13px;

        @media only screen and (max-width: 499px) {
            margin-right: $mobile13px;
        }

        &_info {
            width: 6vw;

            @media only screen and (max-width: 499px) {
                order: 2;
                max-width: 32.5vw;
                width: initial;
                margin-right: auto;
            }
        }

        &_addresses,
        &_noFromTo {
            margin-right: auto;
            flex: 1;
            overflow: hidden;

            @media only screen and (max-width: 499px) {
                order: 4;
                margin-top: $mobile10px;
                margin-right: 0;
                flex: initial;
            }
        }

        &_red,
        &_gray {
            font-family: $Montserrat500;
        }

        &_red {
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $redColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        &_gray {
            font-size: $desctop10px;
            line-height: $desctop17px;
            color: $slateGreyColor;
            padding-bottom: 0.2vw;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile16px;
            }
        }

        &_blockTime {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media only screen and (max-width: 499px) {
                max-width: 25vw;
            }
        }

        &_tx {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 7vw;
            display: block;

            @media only screen and (max-width: 499px) {
                max-width: 35vw;
            }

            &:hover {
                color: $redColor;
                text-decoration: underline;
            }
        }

        &_address {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media only screen and (max-width: 499px) {
                max-width: 100%;
            }

            &:hover {
                color: $redColor;
                text-decoration: underline;
            }
        }

        &_noFromTo {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: $Montserrat500;
            font-size: $desctop12px;
            line-height: $desctop19px;
            color: $slateGreyColor;

            @media only screen and (max-width: 499px) {
                max-width: 100%;
                font-size: $mobile12px;
                line-height: $mobile19px;
                margin-top: 0;
            }
        }

        &_row {
            display: flex;
            align-items: center;
        }

        &_blockRow {
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 499px) {
                margin-left: 14.612vw;
            }
        }

        &_label {
            font-family: $Montserrat500;
            font-size: $desctop10px;
            line-height: $desctop14px;
            color: $bunkerColor;
            margin-right: $desctop6px;
            min-width: $desctop27px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile19px;
                margin-right: $mobile6px;
                min-width: 12.8vw;
            }
        }
    }

    &__reward {
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-family: $Montserrat500;
        font-size: $desctop10px;
        line-height: $desctop17px;
        letter-spacing: $desctop1px;
        color: $slateGreyColor;
        background: rgba(119, 131, 143, 0.1);
        border-radius: $desctop4px;
        padding: $desctop5px $desctop8px;
        margin-left: $desctop13px;
        width: fit-content;

        @media only screen and (max-width: 499px) {
            order: 3;
            font-size: $mobile10px;
            line-height: $mobile17px;
            letter-spacing: $mobile1px;
            border-radius: $mobile5px;
            padding: $mobile5px $mobile7px;
            margin-left: auto;
            max-width: 41vw;
            white-space: nowrap;
            overflow: hidden;
        }

        img {
            width: $desctop8px;
            height: $desctop8px;
            margin-right: $desctop5px;

            @media only screen and (max-width: 499px) {
                width: $mobile10px;
                height: $mobile10px;
                margin-right: $mobile5px;
            }
        }
    }

    &__info {
        @extend .latestTxn__info;

        @media only screen and (max-width: 499px) {
            margin-top: 0 !important;
            order: 2;
        }
    }
}

.spinner {
    background: $whiteColor;
    box-shadow: 0 $desctop4px $desctop6px rgba(0, 0, 0, 0.05);
    border-radius: $desctop8px;
    height: 54.4vw;

    @media only screen and (max-width: 499px) {
        min-height: 65vh;
        box-shadow: 0 $mobile4px $mobile6px rgba(0, 0, 0, 0.05);
        border-radius: $mobile7px;
    }

    &>div {
        height: 100%;
    }
}

.latestTxn {
    @extend .card;

    &__logo {
        @extend .card__logo;
        order: -1;
    }

    &__wrapper {
        margin-right: $desctop13px;

        @media only screen and (max-width: 499px) {
            width: 83%;
            margin-right: 0;
        }
    }

    &__hash {
        @extend .card__item_address;
        @extend .card__item_red;
    }

    &__time {
        @extend .card__item_blockTime;
        @extend .card__item_gray;
        max-width: initial;
    }

    &__info {

        @media only screen and (max-width: 499px) {
            margin-top: $mobile10px;
            margin-left: auto;
        }

        &:not(:last-child) {
            margin-left: auto;
            margin-right: 0.91vw;

            @media only screen and (max-width: 499px) {
                margin: $mobile10px 0 0 14.612vw;
            }
        }

        &_label {
            @extend .card__item_label;
            margin-bottom: $desctop2px;

            @media only screen and (max-width: 499px) {
                margin-bottom: 0;
            }
        }

        &_reward {
            @extend .card__reward;
            margin-left: 0;
            width: 9vw;

            @media only screen and (max-width: 499px) {
                width: 35vw;
            }
        }

        &_value {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 $desctop2px;

            @media only screen and (max-width: 499px) {
                margin: 0 $mobile2px;
            }
        }
    }
}
