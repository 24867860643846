@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.search {
    display: none;

    @media only screen and (max-width: 499px) {
        display: flex;
        padding: $mobile15px;
        margin-left: auto;
        margin-right: $mobile20px;
    }

    &__icon {
        height: $mobile17px;
        width: $mobile17px;

        path {
            fill: $whiteColor;
        }
    }
}

.drawer__opened,
.drawer__closed {
    display: none;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
        display: block;
        background: $whiteColor;
        width: 100vw;
        position: absolute;
        top: $mobile70px;
        left: 0;
        transition: transform 0.5s;
        z-index: 100;
    }
}

.drawer__opened {
    transform: translateX(0);
}

.drawer__closed {
    transform: translateX(150vw);
}


.searchContainer {
    border: $mobile1px solid rgba(38, 38, 66, 0.1);

    &>div {
        border-color: rgba(38, 38, 66, 0.1);

        p {
            color: $tolopeaColor;
        }

        svg path {
            fill: $tolopeaColor;
        }
    }

    &__inputWrapper {
        display: flex;
        width: fit-content;
        border: $desctop1px solid rgba(31, 31, 59, 0.1);
        border-radius: $desctop30px;
        height: $desctop45px;

        @media only screen and (max-width: 499px) {
            justify-content: space-between;
            width: 100%;
            border: $mobile1px solid rgba(31, 31, 59, 0.1);
            border-radius: $mobile30px;
            height: $mobile44px;
        }
    }

    &__input {
        min-width: 40vw;
        height: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        padding-left: $desctop19px;
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop24px;
        color: $midnightExpressColor;

        @media only screen and (max-width: 499px) {
            min-width: auto;
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 $mobile5px 0 $mobile10px;
            font-size: $mobile13px;
            line-height: $mobile24px;
        }

        &::placeholder {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop24px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile24px;
            }
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $redColor;
        border-radius: $desctop32px;
        border: none;
        height: 100%;
        outline: none;
        width: $desctop63px;
        cursor: pointer;
        transition: all 0.25s;

        @media only screen and (max-width: 499px) {
            border-radius: $mobile30px;
            width: $mobile65px;
        }

        &:hover {
            opacity: 0.5;
        }
    }

    &__searchIcon {
        width: $desctop14px;
        height: $desctop14px;

        @media only screen and (max-width: 499px) {
            height: $mobile13px;
            width: $mobile13px;
        }
    }
}
