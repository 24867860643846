@import "../../assets/styles/variables";

@mixin mainBtnStyle {
    cursor: pointer;
    font-family: $Montserrat500;
    font-size: $desctop10px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: $desctop1px;
    outline: none;

    @media only screen and (max-width: 499px) {
        font-size: $mobile10px;
        line-height: 120%;
    }
}

@mixin transparentButton {
    border: $desctop2px solid $whiteColor;
    box-sizing: border-box;
    border-radius: $desctop25px;
    transition: all 0.25s;
    color: $tolopeaColor;
    background: transparent;

    @media only screen and (max-width: 499px) {
        border: $mobile2px solid $whiteColor;
        border-radius: $mobile20px;
    }

    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 2.1vw;
        height: 2.1vw;

        @media only screen and (max-width: 499px) {
            width: $mobile30px;
            height: $mobile30px;
        }

        svg {
            width: $desctop30px;
            height: $desctop30px;
            fill: $redColor;

            @media only screen and (max-width: 499px) {
                width: $mobile30px;
                height: $mobile30px;
            }
        }
    }

    &:hover {
        color: #fff;
        background: $redColor;
        border: $desctop2px solid $redColor;

        @media only screen and (max-width: 499px) {
            border: $mobile2px solid $redColor;
        }

        span {
            svg {
                fill: #fff;
            }
        }

        svg {
            path {
                fill: #fff;
            }
        }
    }
}

@mixin grayTransparentButton {
    border: $desctop2px solid $slateGreyColor;
    box-sizing: border-box;
    border-radius: $desctop25px;
    transition: all 0.25s;
    color: $slateGreyColor;
    background: transparent;

    @media only screen and (max-width: 499px) {
        border: $mobile2px solid $slateGreyColor;
        border-radius: $mobile20px;
    }

    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 2.1vw;
        height: 2.1vw;

        @media only screen and (max-width: 499px) {
            width: $mobile30px;
            height: $mobile30px;
        }

        svg {
            width: $desctop30px;
            height: $desctop30px;
            fill: $slateGreyColor;

            @media only screen and (max-width: 499px) {
                width: $mobile30px;
                height: $mobile30px;
            }
        }
    }

    &:hover {
        color: #fff;
        background: $slateGreyColor;
        border: $desctop2px solid $slateGreyColor;

        @media only screen and (max-width: 499px) {
            border: $mobile2px solid $slateGreyColor;
        }

        span {
            svg {
                fill: #fff;
            }
        }

        svg {
            path {
                fill: #fff;
            }
        }
    }
}

@mixin filledButton {
    border-radius: $desctop32px;
    transition: all 0.25s;
    color: #fff;
    background: $redColor;
    border: none;

    @media only screen and (max-width: 499px) {
        border: $mobile2px solid transparent;
        border-radius: $mobile20px;
    }

    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 2.1vw;
        height: 2.1vw;

        @media only screen and (max-width: 499px) {
            width: $mobile30px;
            height: $mobile30px;
        }

        svg {
            width: $desctop30px;
            height: $desctop30px;
            fill: $whiteColor;

            @media only screen and (max-width: 499px) {
                width: $mobile30px;
                height: $mobile30px;
            }
        }
    }

    &:hover {
        background: rgba(255, 48, 21, 0.5);
    }
}

@mixin sublink {
    position: absolute;
    background: $whiteColor;
    border: $desctop1px solid rgba(36, 36, 68, 0.1);
    border-radius: $desctop17px;
    padding: $desctop10px;
    top: 2.5vw;
    left: 50%;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transform: translate(-50%, 0);

    a {
        font-family: $Montserrat500;
        font-size: $desctop10px;
        line-height: $desctop10px;
        letter-spacing: $desctop1px;
        text-transform: uppercase;
        color: $tolopeaColor;
        display: flex;
        white-space: nowrap;
        justify-content: center;
        padding: $desctop10px;
        text-align: center;

        &:hover {
            color: $redColor;
        }
    }
}


@mixin moreLinks {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 0.9vw $desctop25px 0.9vw $desctop25px;
    z-index: 101;
    font-family: $Montserrat500;
    color: $whiteColor;
    font-size: $desctop10px;
    letter-spacing: $desctop1px;
    text-transform: uppercase;

    &:hover {
        color: $redColor;

        .header__more_subLinks {
            transition: transform 0.4s;
            opacity: 1;
            user-select: auto;
            pointer-events: auto;
            transform: translate(-50%, 0);
        }

        &>a {
            color: $redColor;
        }
    }
}

@mixin moreLinksItem {
    font-family: $Montserrat600;
    font-size: $desctop10px;
    line-height: $desctop10px;
    letter-spacing: $desctop1px;
    text-transform: uppercase;
    color: $blackColor;
    text-align: left;
    border-bottom: $desctop1px solid rgba(38, 38, 66, 0.1);
    padding: $desctop5px $desctop10px $desctop9px $desctop10px;
    cursor: default;
}

@mixin text500tolopeaColor {
    font-family: $Montserrat500;
    font-size: $desctop13px;
    line-height: $desctop19px;
    color: $tolopeaColor;

    @media only screen and (max-width: 499px) {
        font-size: $mobile13px;
        line-height: $mobile19px;
    }
}

@mixin text500red {
    font-family: $Montserrat500;
    font-size: $desctop13px;
    line-height: $desctop19px;
    color: $redColor;

    @media only screen and (max-width: 499px) {
        font-size: $mobile13px;
        line-height: $mobile19px;
    }

    &:hover {
        color: $redColor;
        text-decoration: underline;
    }
}

@mixin dropDownWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: $desctop12px $desctop20px;
    width: 100%;
    z-index: 10;
    border: $desctop1px solid rgba(38, 38, 66, 0.1);
    border-radius: $desctop8px;

    @media only screen and (max-width: 499px) {
        border-radius: $mobile10px;
        height: $mobile44px;
        border: $mobile1px solid rgba(38, 38, 66, 0.1);
        padding: $mobile12px $mobile20px;
    }
}
