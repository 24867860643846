@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixin';

.header {

    &__rightSide {
        &_logo {
            width: $desctop22px;
            height: $desctop22px;
            box-sizing: content-box;

            @media only screen and (max-width: 499px) {
                width: $mobile21px;
                height: $mobile21px;
            }
        }

        &_logoBorder {
            border: $desctop1px solid $whiteColor;

            @media only screen and (max-width: 499px) {
                border: none;
            }
        }

        &_name {
            font-family: $Montserrat500;
            font-size: $desctop10px;
            line-height: $desctop10px;
            letter-spacing: $desctop1px;
            text-transform: uppercase;
            color: $whiteColor;
            margin: 0 $desctop5px 0 $desctop10px;
            white-space: nowrap;
            width: 6vw;
            overflow: hidden;
            text-overflow: ellipsis;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
                letter-spacing: $mobile1px;
                margin: 0 $mobile10px;
                width: initial;
                max-width: 50vw;
                color: $tolopeaColor;
            }
        }
    }

    &__logoWrapper {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 499px) {
            padding: 4.7vw 0;
        }

        &:hover > * {
            color: $redColor;
        }
    }

    &__login {
        display: flex;
        align-items: center;
        margin: 0 $desctop15px 0 0;
        white-space: nowrap;

        @media only screen and (max-width: 499px) {
            margin-right: $mobile10px;
            padding: 4.7vw 0;
        }

        &:hover > * {
            transition: all 0.2s;

            color: $redColor;
            path {
                fill: $redColor;
            }
        }

        & > * {
            font-family: $Montserrat500;
            color: $whiteColor;
            font-size: $desctop10px;
            letter-spacing: $desctop1px;
            text-transform: uppercase;

            @media only screen and (max-width: 499px) {
                font-family: $Montserrat500;
                color: $tolopeaColor;
                font-size: $mobile10px;
                letter-spacing: $mobile1px;
            }
        }

        &_icon {
            width: $desctop17px;
            height: $desctop17px;
            margin-right: $desctop9px;

            @media only screen and (max-width: 499px) {
                width: $mobile17px;
                height: $mobile17px;
                margin-right: $mobile10px;

                path {
                    fill: $tolopeaColor;
                }
            }
        }
    }
}
