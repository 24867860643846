@import '../../../assets/styles/variables';

.container {
    flex: 1 1;
    background: $whiteSmokeColor;

    @media only screen and (max-width: 499px) {
        background: $whiteColor;
    }
}

.wrapper {
    display: flex;
    margin: $desctop20px 5.2vw $desctop20px 8vw;

    @media only screen and (max-width: 499px) {
        margin: $mobile15px $mobile20px $mobile34px $mobile20px ;
    }
}
