@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.charts {
    background: $whiteColor;
    padding: $desctop45px 6vw $desctop30px 8vw;
    flex: 1;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $midnightExpressColor;
        margin-bottom: $desctop30px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile24px;
            line-height: $mobile24px;
            margin: 0 0 $mobile15px 0;
            width: 100%;
        }
    }

    &__subTitle {
        font-family: $Montserrat900;
        font-size: $desctop20px;
        line-height: $desctop20px;
        color: $midnightExpressColor;
        margin-bottom: $desctop30px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile20px;
            line-height: $mobile20px;
            margin: 0 0 $mobile15px 0;
            width: 100%;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &_item {
            display: flex;
            align-items: flex-start;
            width: 48%;
            height: 30vw;
            background: $whiteColor;
            box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
            border-radius: $desctop8px;
            padding: $desctop10px $desctop10px 0 $desctop10px;
            margin-bottom: $desctop30px;
            overflow: hidden;

            & > * {
                width: 100%;
            }

            @media only screen and (max-width: 499px) {
                display: initial;
                box-shadow: 0 $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
                border-radius: $mobile7px;
                padding: $mobile20px 0;
                width: 100%;
                height: 100%;
                max-height: initial;
                margin-bottom: $mobile15px;
            }
        }
    }
}

.spinnerWrapper {
    flex: 1;
    position: relative;
    min-height: 8vw;

    @media only screen and (max-width: 499px) {
        min-height: 18vh;
    }
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
}

.spinnerChart {
    display: flex;
    align-items: center;
    height: 26vw;

    @media only screen and (max-width: 499px) {
        min-height: 55vh;
    }

    &>div {
        height: 100%;
    }
}
