@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.block {
    background: $whiteColor;
    padding: $desctop45px 6vw $desctop15px 8vw;
    flex: 1;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $midnightExpressColor;
        margin-bottom: $desctop19px;

        @media only screen and (max-width: 499px) {
            white-space: nowrap;
            font-size: 6vw;
            line-height: 6vw;
            margin-bottom: $mobile30px;
        }
    }

    &__topWrapper {
        display: flex;
        align-items: center;
    }

    &__top {
        padding: $desctop20px;
        background: $whiteColor;
        box-shadow: 0px $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        margin-bottom: $desctop15px;

        @media only screen and (max-width: 499px) {
            padding: $mobile20px;
            box-shadow: 0px $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
            border-radius: $mobile10px;
            margin-bottom: $mobile20px;
        }

        &_address {
            font-family: $Montserrat600;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $midnightExpressColor;
            display: flex;

            @media only screen and (max-width: 499px) {
                margin: 0 auto;
                font-size: $mobile13px;
                line-height: $mobile19px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
            }
        }

        &_wrapper {
            display: flex;

            @media only screen and (max-width: 499px) {
                flex-wrap: wrap;
            }
        }

        &_date {
            font-family: $Montserrat500;
            font-size: $desctop12px;
            line-height: $desctop17px;
            color: $suvaGreyColor;
            margin-right: $desctop20px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile12px;
                line-height: $mobile17px;
                margin-right: 0;
                width: 100%;
                margin-bottom: $mobile5px;
            }
        }

        &_transactions {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $midnightExpressColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }

            span {
                background: rgba(119, 131, 143, 0.1);
                border-radius: $desctop4px;
                padding: $desctop2px $desctop6px;
                margin-left: $desctop5px;
                font-family: $Montserrat500;
                font-size: $desctop10px;
                line-height: $desctop17px;
                letter-spacing: $desctop1px;
                color: $slateGreyColor;

                @media only screen and (max-width: 499px) {
                    border-radius: $mobile5px;
                    padding: $mobile2px $mobile6px;
                    margin-left: $mobile5px;
                    font-size: $mobile10px;
                    line-height: $mobile17px;
                    letter-spacing: $mobile1px;
                }
            }
        }

        &_arrowLeft,
        &_arrowRight {
            display: flex;
            align-items: center;

            .arrowDisabled {
                svg {
                    cursor: default;
                }
            }

            &:hover {
                svg {
                    opacity: 0.3;
                }
            }

            svg {
                cursor: pointer;
                width: $desctop11px;
                height: $desctop11px;

                @media only screen and (max-width: 499px) {
                    width: $mobile12px;
                    height: $mobile12px;
                }
            }
        }

        &_arrowLeft {
            padding-right: $desctop10px;

            @media only screen and (max-width: 499px) {
                padding-right: $mobile10px;
            }
        }

        &_arrowRight {
            padding-left: $desctop10px;

            @media only screen and (max-width: 499px) {
                padding-left: $mobile10px;
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        margin: $desctop30px 0;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
            margin: $mobile15px 0;
        }

        &_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 17vw;
            padding: $desctop20px;
            background: $whiteColor;
            box-shadow: 0px $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
            border-radius: $desctop8px;

            @media only screen and (max-width: 499px) {
                width: 100%;
                padding: $mobile20px;
                box-shadow: 0px $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
                border-radius: $mobile10px;
                margin-bottom: $mobile20px;
            }
        }

        &_label,
        &_value {
            text-align: center;
        }

        &_label {
            font-family: $Montserrat900;
            font-size: $desctop14px;
            line-height: $desctop19px;
            color: #313162;
            margin-bottom: $desctop10px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        &_value {
            font-family: $Montserrat500;
            font-size: $desctop14px;
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        &_icon {
            width: $desctop20px;
            height: $desctop20px;
            margin-bottom: $desctop10px;

            @media only screen and (max-width: 499px) {
                width: $mobile20px;
                height: $mobile20px;
                margin-bottom: $mobile10px;
            }
        }
    }
}

.transaction {
    padding: 0 $desctop3px;

    &__row {
        justify-content: space-between;
        padding: $desctop20px;
        background: $whiteColor;
        box-shadow: 0px $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        margin-bottom: $desctop30px;
        display: flex;

        @media only screen and (max-width: 499px) {
            padding: $mobile20px;
            box-shadow: 0px $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
            border-radius: $mobile10px;
            margin-bottom: $mobile15px;
            flex-wrap: wrap;
        }

        &_staking {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        &:not(:last-child) {
            margin-bottom: $desctop15px;
        }

        @media only screen and (max-width: 499px) {
            &:not(:last-child) {
                margin-bottom: $mobile15px;
            }
        }

        &_dotWrapper,
        &_dotWrapperDisabled {
            padding: $desctop10px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
                .transactions__row_dot {
                    opacity: 0.8;
                }
            }
        }

        &_dotWrapperDisabled {
            cursor: default;
        }

        &_dot {
            display: flex;
            align-items: center;
            justify-content: center;
            width: $desctop15px;
            height: $desctop15px;

            @media only screen and (max-width: 499px) {
                width: $mobile15px;
                height: $mobile15px;
            }

            img,svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__item {
        &_label {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $slateGreyColor;
            margin-bottom: $desctop5px;
            height: $desctop20px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile13px;
                margin-bottom: $mobile5px;
                height: $mobile12px;
            }
        }

        &_hash,
        &_valueOut,
        &_fee,
        &_empty {
            &:not(:last-child) {
                margin-right: $desctop20px;
            }

            @media only screen and (max-width: 499px) {
                &:not(:last-child) {
                    margin-right: $mobile20px;
                }
            }

            a,
            span {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-family: $Montserrat500;
                font-size: $desctop13px;
                line-height: $desctop19px;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile13px;
                    line-height: $mobile19px;
                    width: 100% !important;
                }
            }

            a {
                color: $redColor;

                &:hover {
                    color: $redColor;
                    text-decoration: underline;
                }
            }

            span {
                color: $nightRiderColor;
            }
        }

        &_hash {
            width: 43vw;

            @media only screen and (max-width: 499px) {
                width: 80%;
            }
        }

        &_valueOut {
            min-width: 11vw;

            @media only screen and (max-width: 499px) {
                width: 50%;
                margin: $mobile15px 0 0 10vw !important;
            }

            a,
            span {
                width: 11vw;
            }
        }

        &_fee,
        &_empty {
            min-width: 11vw;
            @media only screen and (max-width: 499px) {
                margin-top: $mobile15px;
                min-width: 35%;
            }

            span {
                width: 11vw;

                @media only screen and (max-width: 499px) {
                    width: initial;
                }
            }
        }

        &_empty {
            @media only screen and (max-width: 499px) {
                margin-left: 10vw;
            }
        }
    }
}

.rawBlock {
    margin: 0 $desctop3px;
    padding: $desctop20px;
    background: $whiteColor;
    box-shadow: 0px $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
    border-radius: $desctop8px;
    margin-bottom: $desctop30px;

    @media only screen and (max-width: 499px) {
        padding: $mobile20px;
        box-shadow: 0px $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
        border-radius: $mobile10px;
        margin-bottom: $mobile15px;
    }

    &__wrapper {
        border: $desctop1px solid rgba(36, 36, 68, 0.1);
        border-radius: $desctop17px;
        padding: $desctop15px $desctop19px;

        @media only screen and (max-width: 499px) {
            border: $mobile1px solid rgba(36, 36, 68, 0.1);
            border-radius: $mobile17px;
            padding: $mobile15px $mobile19px;
        }

        pre {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $slateGreyColor;

            &::-webkit-scrollbar {
                display: none;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }
    }
}

.notFoundIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: $desctop30px 0;
    min-height: 70vh;

    @media only screen and (max-width: 499px) {
        margin: $mobile30px 0;
    }

    img {
        width: 17.5vw;
        height: 16vw;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            width: 60vw;
            height: 52vw;
            margin-bottom: $mobile20px;
        }
    }

    &__title {
        font-family: $Montserrat900;
        font-size: $desctop24px;
        line-height: $desctop24px;
        color: $blackColor;
        margin-bottom: $desctop17px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile16px;
            line-height: $mobile24px;
            margin-bottom: 0;
            text-align: center;
        }
    }
}

.arrowDisabled {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
}


.details {
    display: flex;
    flex-direction: column;
    background: $whiteColor;
    box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
    border-radius: $desctop8px;
    padding: $desctop14px $desctop40px $desctop14px $desctop40px;
    margin: $desctop3px $desctop3px $desctop22px $desctop3px;
    display: none;

    @media only screen and (max-width: 499px) {
        flex-direction: column;
        padding: $mobile20px;
        box-shadow: 0px $mobile2px $mobile6px rgba(0, 0, 0, 0.07);
        border-radius: $mobile10px;
        margin: $mobile4px;
    }

    &__wrapper {
        display: flex;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    &__arrow {
        min-width: $desctop12px;
        min-height: $desctop12px;
        margin: 2.8vw 2vw 0;

        @media only screen and (max-width: 499px) {
            min-width: $mobile12px;
            min-height: $mobile12px;
            margin: 0 auto $mobile25px;
            transform: rotate(90deg);
        }

        path {
            fill: $tolopeaColor
        }
    }

    &__rightSide,
    &__leftSide {
        width: 100%;
    }

    &__labelWrapper {
        display: flex;
        align-items: center;

        .details__label {
            &:last-child {
                margin-left: auto;
                width: 13.5vw;

                @media only screen and (max-width: 499px) {
                    width: 37.5vw;
                }
            }
        }
    }

    &__label {
        font-family: $Montserrat500;
        font-size: $desctop13px;
        line-height: $desctop19px;
        color: $midnightExpressColor;
        margin-bottom: $desctop11px;

        @media only screen and (max-width: 499px) {
            font-size: $mobile14px;
            line-height: $mobile20px;
            margin-bottom: $mobile10px;
        }
    }

    &__rowWrapper {
        display: flex;
        flex-direction: column;
    }

    &__row {
        display: flex;
        align-items: center;
        background: $whiteColor;
        box-shadow: 0 $desctop2px $desctop6px rgba(0, 0, 0, 0.07);
        border-radius: $desctop8px;
        padding: $desctop10px $desctop20px;
        margin-bottom: $desctop20px;

        @media only screen and (max-width: 499px) {
            box-shadow: none;
            border-radius: $desctop8px;
            padding: 0;
            margin-bottom: $mobile20px;
        }

        &_staking {
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;
            color: $tolopeaColor;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }
        }

        a {
            color: $redColor;
            font-family: $Montserrat500;
            font-size: $desctop13px;
            line-height: $desctop19px;

            @media only screen and (max-width: 499px) {
                font-size: $mobile13px;
                line-height: $mobile19px;
            }

            &:hover {
                color: $redColor;
                text-decoration: underline;
            }
        }

        &_value {
            display: flex;
            align-items: center;
            margin-left: auto;
            width: 12vw;

            @media only screen and (max-width: 499px) {
                width: 37vw;
            }

            img {
                width: $desctop10px;
                height: $desctop10px;
                margin-right: $desctop8px;

                @media only screen and (max-width: 499px) {
                    min-width: $mobile10px;
                    min-height: $mobile10px;
                    margin-right: $mobile6px;
                }
            }

            p {
                font-family: $Montserrat500;
                font-size: $desctop12px;
                line-height: $desctop17px;
                color: $slateGreyColor;

                @media only screen and (max-width: 499px) {
                    font-size: $mobile12px;
                    line-height: $mobile17px;
                    white-space: nowrap;
                }
            }
        }
    }

    &__total {
        display: flex;
        justify-content: space-between;
        margin-top: $desctop20px;

        @media only screen and (max-width: 499px) {
            flex-direction: column;
        }

        p {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-family: $Montserrat500;
            font-size: $desctop10px;
            line-height: $desctop17px;
            letter-spacing: $desctop1px;
            color: $slateGreyColor;
            background: rgba(119, 131, 143, 0.1);
            border-radius: $desctop4px;
            padding: $desctop5px $desctop8px;
            width: fit-content;

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile17px;
                letter-spacing: $mobile1px;
                border-radius: $mobile5px;
                padding: $mobile5px $mobile7px;
                margin-left: 0;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
                justify-content: center;

                &:not(:last-child) {
                    margin-bottom: $mobile20px;
                }
            }
        }

        img {
            width: $desctop9px;
            height: $desctop9px;
            margin-right: $desctop5px;

            @media only screen and (max-width: 499px) {
                width: $mobile10px;
                height: $mobile10px;
                margin-right: $mobile5px;
            }
        }
    }
}

.detailsOpened {
    display: flex;
}
